import { ResponsiveLine, Serie } from "@nivo/line";
import { timeFormat } from "d3-time-format";
import { pluralize } from "../../lib/string";

interface Props {
  data: Serie[];
  colors?: any;
  legends?: any[];
  curve?: "monotoneX" | "linear";
  type?: "time" | "linear";
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  valueSuffix?: string;
  markers?: any[];
}
export default function Line({
  data,
  colors = ["#1A41CF"],
  legends = [],
  type = "time",
  margin = { top: 40, right: 40, bottom: 56, left: 40 },
  valueSuffix = "",
  markers = [],
}: Props) {
  const format = type === "time" ? "%Y-%m-%d" : ">-.2d";
  const customTimeFormat = timeFormat("%d %b");
  const ys = data[0].data.map((d) => d.y as number);
  const xs =
    type !== "time"
      ? data[0].data.map((d) => d.x as number)
      : Array.from(data[0].data.map((_, i) => i));
  const minX = Math.min(...xs);
  const maxX = Math.max(...xs);
  const maxY = Math.max(...ys);
  const MIN_AXIS_HEIGHT_TO_AVOID_FRACTIONS = 8;
  const maxYAxis =
    maxY < MIN_AXIS_HEIGHT_TO_AVOID_FRACTIONS
      ? MIN_AXIS_HEIGHT_TO_AVOID_FRACTIONS
      : maxY + Math.round(maxY * 0.1);

  const range = maxX - minX;
  let tickValues;
  if (type === "time") {
    const maxTicks = 50;
    tickValues = "every ";
    if (range < maxTicks) {
      tickValues += "day";
    } else if (range >= maxTicks && range < maxTicks * 7) {
      tickValues += "week";
    } else if (range >= maxTicks * 7 && range < maxTicks * 90) {
      tickValues += "3 months";
    } else {
      tickValues += "year";
    }
  } else {
    tickValues = [];

    const tickInterval = range > 10 ? Math.round(range / 10) + 1 : 1;
    for (let i = minX; i <= maxX; i += tickInterval) {
      tickValues.push(i);
    }
  }

  let xScaleObj;
  if (type === "time") {
    xScaleObj = {
      format,
      type,
    };
  }
  if (type === "linear") {
    xScaleObj = {
      format,
      type,
      min: minX,
    };
  }

  return (
    <ResponsiveLine
      curve="linear"
      data={data}
      margin={margin}
      xScale={xScaleObj}
      yScale={{
        type: "linear",
        min: 0,
        max: maxYAxis,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 4,
        format: (value) => {
          return type === "time"
            ? customTimeFormat(value)
            : pluralize(value, valueSuffix);
        },
        tickPadding: 12,
        tickRotation: -45,
        legendOffset: 40,
        legendPosition: "middle",
        tickValues: tickValues,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={colors}
      enablePoints={true}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      enableGridX={true}
      enableArea={true}
      areaBaselineValue={0}
      areaOpacity={0.08}
      useMesh={true}
      tooltip={(tooltip) => (
        <div
          style={{
            background: "white",
            padding: "16px",
            border: "1px solid #EDEEEC",
            fontSize: "13px",
            color: "#1E293B",
            borderRadius: "4px",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
          }}
        >
          <span className="font-medium tracking-tight">
            {tooltip.point.data.xFormatted}
          </span>
          <br />
          <span className="text-[#64748b]">
            Value: {tooltip.point.data.yFormatted}
          </span>
        </div>
      )}
      markers={markers}
      legends={legends}
    />
  );
}
