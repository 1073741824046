import { CohortType, AttributeTarget } from "../__generated__/graphql";
import {
  AttributeSources,
  MachineAttributePrefixes,
} from "../types/BackendConstants";
import { BinaryFilterCondition } from "../types/BackendTypes";

export enum CohortTabs {
  LastEntered = "lastEntered",
  LastLeft = "lastLeft",
}

export function isCohortDirty(a: CohortType, b: CohortType): boolean {
  if (!a || !b) return false;
  if (JSON.stringify(a.audience) !== JSON.stringify(JSON.parse(b.audience)))
    return true;
  else if (
    JSON.stringify(a.observing) !== JSON.stringify(JSON.parse(b.observing))
  )
    return true;
  else if (
    JSON.stringify(a.aiSummarySettings.period) !==
    JSON.stringify(JSON.parse(b?.aiSummarySettings.period || "null"))
  )
    return true;
  else if (
    JSON.stringify(a.aiSummarySettings.action) !==
    JSON.stringify(JSON.parse(b?.aiSummarySettings.action || "null"))
  )
    return true;
  else return false;
}

export function canCohortBeSetLive(cohort: CohortType): boolean {
  return (
    cohort &&
    cohort.targetMemberType &&
    cohort.audience.criteria.length > 0 &&
    cohort.observing.criteria.length > 0
  );
}

export function cohortToFilter(
  cohortId: string,
  attributeTarget: AttributeTarget,
): BinaryFilterCondition {
  return {
    source: AttributeSources.Cohort,
    path: `${MachineAttributePrefixes.CohortCurrentMember}${cohortId}`,
    target: attributeTarget,
    operator: "EQ",
    value: true,
  } as BinaryFilterCondition;
}
