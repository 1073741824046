import React, { ReactNode } from "react";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { cx } from "../lib/cx";

interface Props {
  title: string;
  description: string;
  icon?: ReactNode;
  buttonLabel?: string;
  fullHeight?: boolean;
  onClick?: () => void;
}

export default function EmptyState({
  title,
  description,
  buttonLabel,
  icon,
  fullHeight = true,
  onClick,
}: Props) {
  return (
    <div
      className={cx(
        fullHeight
          ? "h-auto place-items-center"
          : "grid h-full w-auto p-8 text-center rounded-lg",
      )}
      data-testid="patterns__empty-state"
    >
      <div>
        <div className="mx-auto flex h-60 w-60 flex-row items-center pb-4">
          {icon}
        </div>

        <div className="mx-auto pb-4 text-center">
          <p className="flex-row text-md font-bold text-body-text">{title}</p>

          <p className="flex-row text-sm font-normal text-body-text-lighter">
            {description}
          </p>
        </div>

        <div className="mx-auto w-3/4 flex justify-center">
          {buttonLabel && (
            <PrimaryButton label={buttonLabel} onClick={onClick} />
          )}
        </div>
      </div>
    </div>
  );
}
