import { BehaviorType } from "../__generated__/graphql";
import Line from "../patterns/charts/Line";

export default function EnteredCompletedBarChart({
  lifecycleStageBehavior,
}: {
  lifecycleStageBehavior: BehaviorType;
}) {
  const { entered, completed } = lifecycleStageBehavior.stats;
  return (
    <div className="h-80">
      <Line
        data={[
          {
            id: "Completed",
            data: completed.timeSeries,
          },
          {
            id: "Entered",
            data: entered.timeSeries,
          },
        ]}
        type={"time"}
        colors={["#1A41CF", "#64748b"]}
        curve="monotoneX"
        margin={{ top: 40, right: 40, bottom: 80, left: 40 }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 80,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
