import { cx } from "../lib/cx";

interface Props {
  bgColor?: string;
  classNames?: string;
  onClick?: () => void;
}

export default function Card({
  children,
  bgColor = "bg-white",
  classNames = "",
  onClick,
}: Props & React.PropsWithChildren) {
  return (
    <div
      className={cx(
        "flex grow flex-col rounded-md p-4 animate",
        bgColor,
        classNames,
        onClick && "cursor-pointer",
        onClick && "hover:shadow-md",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
