import React, { useMemo } from "react";
import PageHeader from "../../../patterns/PageHeader";
import LabelAndToggle from "../../../campaign_form/label-and-toggle";
import DrawerComponentProps from "./drawer-component-props";
import SummarySettingsAction from "../../../actions/SummarySettingsAction";
import { MachineStatusChoices } from "../../../__generated__/graphql";
import Alert from "../../../patterns/Alert";

export default function SummarySettingsDrawer({
  dispatch,
  cohort,
}: DrawerComponentProps) {
  const aiSummarySettings = cohort.aiSummarySettings;
  const enabled = useMemo(() => {
    console.log("enabled", cohort.aiSummarySettings?.period);
    return !!cohort.aiSummarySettings?.period;
  }, [cohort.aiSummarySettings.period]);

  const isEditable = cohort.status === MachineStatusChoices.Draft;

  return (
    <div>
      <PageHeader
        header="Summary Settings"
        subhead="Periodically generate a summary of recent account activity. Optionally, send the summary to a slack channel or DM."
      />
      <div className="px-8 mb-8">
        <LabelAndToggle
          label="Generate a summary of recent activity"
          isOn={enabled}
          disabled={!isEditable}
          onChange={() => {
            enabled
              ? dispatch({ type: "RemoveAiHealthSummary" })
              : dispatch({ type: "EnableAiHealthSummary" });
          }}
        />
        {!enabled && !isEditable && (
          <div className="mb-2">
            <Alert
              message={`You can't enable periodic summaries after the cohort has been set live.`}
              type="warning"
              size="small"
            />
          </div>
        )}
        {enabled && (
          <SummarySettingsAction
            dispatch={dispatch}
            period={aiSummarySettings.period}
            action={aiSummarySettings.action}
            isEditable={isEditable}
          />
        )}
      </div>
    </div>
  );
}
