import "../loading.css";

// You can accept props in the function signature
export default function SkeletonScreen({ label = "Loading your data" }) {
  return (
    <div className="flex items-center align-middle justify-center w-full min-h-96">
      <div className="w-64">
        {/* Use the optional size prop */}
        <div className="square mx-auto"></div>
        <div className="pt-4 mx-auto block text-center items-center justify-center animate-pulse text-body-text-lighter">
          {label}...
        </div>
      </div>
    </div>
  );
}
