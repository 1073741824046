import React, { Dispatch, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_LOOPS_INTEGRATIONS, GET_SLACK_CHANNELS } from "../graphql/queries";
import Card from "../patterns/Card";
import {
  AiSummarizeHealthAction,
  AiSummarizeHealthToSlackAction,
} from "../types/BackendTypes";
import { CohortUpdate } from "../cohort/cohort_form/reducer";
import Input from "../patterns/forms/Input";
import SelectInput from "../patterns/forms/SelectInput";
import Alert from "../patterns/Alert";

interface PeriodType {
  waitFor: number;
  timeUnit: string;
}

export default function SummarySettingsAction({
  dispatch,
  period,
  action,
  isEditable,
}: {
  dispatch: Dispatch<CohortUpdate>;
  period: PeriodType;
  action: AiSummarizeHealthAction | AiSummarizeHealthToSlackAction;
  isEditable: boolean;
}) {
  const [waitFor, setWaitFor] = useState<number>(period?.waitFor || 30);
  const [timeUnit, setTimeUnit] = useState(period?.timeUnit || "days");
  const [sendToSlack, setSendToSlack] = useState(
    action.type === "ai_summarize_health_to_slack" || false,
  );

  const timeUnitOptions = ["seconds", "minutes", "hours", "days"];
  const {
    data: loopsIntegrationData,
    loading: loopsIntegrationLoading,
    error: loopsIntegrationError,
  } = useQuery(GET_LOOPS_INTEGRATIONS);
  const {
    data: slackData,
    loading: slackChannelsLoading,
    error: slackChannelsError,
  } = useQuery(GET_SLACK_CHANNELS);

  const sendToSlackDisabled = useMemo(() => {
    const loadingOrError =
      loopsIntegrationLoading ||
      slackChannelsLoading ||
      !!loopsIntegrationError ||
      !!slackChannelsError;
    if (loadingOrError) {
      return true;
    }
    const noLoopsIntegration =
      loopsIntegrationData?.loopsIntegrations.length === 0;
    const noSlackChannels = !slackData?.slackChannels;
    return loadingOrError || noLoopsIntegration || noSlackChannels;
  }, [
    loopsIntegrationData,
    slackData,
    loopsIntegrationLoading,
    slackChannelsLoading,
    loopsIntegrationError,
    slackChannelsError,
  ]);

  return (
    <div>
      <Card>
        {!isEditable && (
          <div className="mb-2">
            <Alert
              message={`You can't change the schedule for summary settings after the cohort has gone live. You can only change the slack settings.`}
              type="warning"
              size="small"
            />
          </div>
        )}
        <div className="text-sm">Generate a summary every</div>
        <div className="flex flex-row gap-2">
          <div>
            <Input
              type="number"
              value={waitFor}
              placeholder="30"
              required={true}
              disabled={!isEditable}
              onChange={(w) => {
                setWaitFor(parseInt(w));
                dispatch({
                  type: "UpdateAiHealthSummaryPeriod",
                  data: {
                    period: {
                      version: "1",
                      type: "wait",
                      waitFor: parseInt(w),
                      timeUnit,
                    },
                  },
                });
              }}
            />
          </div>
          <div>
            <SelectInput
              label=""
              placeholder="Pick a unit of time"
              required={true}
              disabled={!isEditable}
              value={timeUnit}
              options={timeUnitOptions.map((tu) => ({
                label: tu,
                value: tu,
              }))}
              onChange={(tu) => {
                setTimeUnit(tu);
                dispatch({
                  type: "UpdateAiHealthSummaryPeriod",
                  data: {
                    period: {
                      version: "1",
                      type: "wait",
                      waitFor,
                      timeUnit: tu,
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <label>
              <input
                type="checkbox"
                className="mr-2"
                defaultChecked={sendToSlack}
                disabled={sendToSlackDisabled}
                onChange={(e) => {
                  setSendToSlack(e.target.checked);
                  e.target.checked
                    ? dispatch({
                        type: "EnableAiHealthSummaryToSlack",
                      })
                    : dispatch({
                        type: "EnableAiHealthSummary",
                      });
                }}
              />
              Send to Slack?
            </label>
          </div>
          {sendToSlack &&
            !sendToSlackDisabled &&
            action.type === "ai_summarize_health_to_slack" && (
              <SelectInput
                label=""
                description={"Notify a slack channel with this message"}
                placeholder="Pick a channel"
                required={true}
                value={action.channel || ""}
                options={slackData.slackChannels.map((s) => ({
                  label: s.name,
                  value: s.id,
                }))}
                disabled={false}
                onChange={(s) =>
                  dispatch({
                    type: "UpdateAiHealthSummaryToSlackChannel",
                    data: {
                      channel: s,
                    },
                  })
                }
              />
            )}
        </div>
      </Card>
    </div>
  );
}
