import { CohortType } from "../../../__generated__/graphql";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";
import SettingsBlock from "../../../campaign_form/SettingsBlock";

interface Props {
  cohort: CohortType;
  openDrawer: () => void;
}

export default function SummarySettingsSection({ cohort, openDrawer }: Props) {
  const waitFor = cohort.aiSummarySettings?.period?.waitFor;
  const timeUnit = cohort.aiSummarySettings?.period?.timeUnit;
  const sendToSlack =
    cohort.aiSummarySettings?.action?.type === "ai_summarize_health_to_slack";

  const baseTitle = sendToSlack
    ? "Send a summary to Slack every"
    : "Generate a summary every";
  return (
    <div onClick={openDrawer}>
      <BuilderSectionHeader
        label="Periodic account summaries"
        description="Periodically generate a summary of recent account activity. Optionally, send the summary to a slack channel or DM"
        isValid={true}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {waitFor && timeUnit && (
          <SettingsBlock
            title={`${baseTitle} ${waitFor} ${timeUnit}`}
            section="summary"
            onClick={() => {}}
          />
        )}
      </div>
    </div>
  );
}
