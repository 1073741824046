import {
  TrophyIcon,
  ClockIcon,
  UsersIcon,
  BriefcaseIcon,
  LifebuoyIcon,
  CheckIcon,
  EnvelopeOpenIcon,
  CursorArrowRaysIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline"; // Import all icons

interface Props {
  title: string;
  stat?: string | number;
  subtitle?: string;
  icon?: keyof typeof iconMap; // icon is optional and refers to a key in iconMap
}

export const iconMap = {
  "Total people entered": UsersIcon,
  "Total organizations entered": BriefcaseIcon,
  "Total people completed": TrophyIcon,
  "Total organizations completed": TrophyIcon,
  "Average time to completion": ClockIcon,
  "Total exited without incentive": LifebuoyIcon,
  "Incentives claimed": LifebuoyIcon,
  Delivered: CheckIcon,
  Opened: EnvelopeOpenIcon,
  Clicked: CursorArrowRaysIcon,
  Bounced: ArrowTrendingUpIcon,
  Unsubscribed: ExclamationTriangleIcon,
};

export default function StatsHeading({ title, stat, subtitle, icon }: Props) {
  const IconComponent = icon && iconMap[icon];

  return (
    <div className="flex flex-row">
      {IconComponent && (
        <div className="w-10 h-10 border border-grey-300 rounded-full flex justify-center items-center mr-3">
          {/* Render the icon with specific color */}
          <IconComponent className="inline-block w-4" />
        </div>
      )}
      <div className={`flex flex-col ${IconComponent ? "my-2.5" : ""}`}>
        <div className="text-xs text-body-text leading-5">{title}</div>
        <div className="font-normal text-4xl my-1">{stat}</div>
        <div className="inline-flex text-xs text-body-text-lightest">
          {subtitle}
        </div>
      </div>
    </div>
  );
}
