import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/16/solid";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  BehaviorType,
  ObjectiveCohortRiskType,
} from "../__generated__/graphql";
import { GET_CAMPAIGNS_TARGETING_MACHINE_INSTANCE_ID } from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import CampaignAction from "./actions/CampaignAction";
import CampaignEntry from "./CampaignEntry";

export default function CohortRisk({
  objective,
  risk,
  close,
}: {
  objective: BehaviorType;
  risk: ObjectiveCohortRiskType;
  close: () => void;
}) {
  const [plan, setPlan] = useState({
    campaign: null,
    cohort: null,
  });

  const { data, loading } = useQuery(
    GET_CAMPAIGNS_TARGETING_MACHINE_INSTANCE_ID,
    {
      variables: { machineInstanceId: objective.id, cohortId: risk.cohortId },
      fetchPolicy: "cache-and-network",
    },
  );

  useEffect(() => {
    if (data?.campaignsTargetingMachineInstanceId.length > 0) {
      setPlan({
        campaign: data.campaignsTargetingMachineInstanceId[0],
        cohort: {
          id: risk.cohortId,
          name: risk.cohortName,
        },
      });
    }
  }, [
    data?.campaignsTargetingMachineInstanceId,
    risk.cohortId,
    risk.cohortName,
  ]);

  if (loading) return <SkeletonScreen label="Talking to Agents..." />;

  return (
    <Disclosure key="p1" as="div" defaultOpen={true} className="">
      {({ open }) => (
        <>
          <DisclosureButton className="group flex flex-row items-center w-full">
            <div className="flex flex-row items-center gap-1 py-3 text-body-text border-l border-body-text pl-4 ml-3 w-full justify-between ">
              <div className="flex flex-row items-center justify-start gap-2">
                <ExclamationTriangleIcon className="w-5 h-5 " />
                <div className="flex flex-row gap-1">
                  <span className="font-semibold ">
                    {risk.numberOfOrgs} {risk.cohortName}
                  </span>
                  customers at risk, worth{" "}
                  <span className="font-semibold">
                    ${risk.totalRevenue.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="flex justify-end">
                <PlusCircleIcon
                  className={`w-5 h-5 ${open ? "hidden" : "block"}`}
                />
                <MinusCircleIcon
                  className={`w-5 h-5 ${open ? "block" : "hidden"}`}
                />
              </div>
            </div>
          </DisclosureButton>
          <Transition
            show={open}
            enter="transition-all duration-500 ease-in-out"
            enterFrom="opacity-0 max-h-0"
            enterTo="opacity-100 max-h-screen"
            leave="transition-all duration-500 ease-in-out"
            leaveFrom="opacity-100 max-h-screen"
            leaveTo="opacity-0 max-h-0"
          >
            <DisclosurePanel
              as="div"
              className="pt-1.5 px-4 pb-4 text-body-text border-l border-body-text ml-3 overflow-hidden"
            >
              <div className="flex flex-col gap-2.5 ml-7">
                {plan.campaign ? (
                  <>
                    <div className="grow text-left">Campaign created:</div>
                    <CampaignEntry campaign={plan.campaign} close={close} />
                  </>
                ) : (
                  <>
                    <div className="grow text-left">Suggested Next Steps:</div>
                    <CampaignAction
                      objective={objective}
                      risk={risk}
                      setPlan={setPlan}
                    />
                  </>
                )}
              </div>
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
