import { COMPLETION_RATE_THRESHOLD } from "../ai_agent/AiAgentDrawer";
import EngagementCircle from "./EngagementCircle";

export default function LifecycleStageStatePill({
  completedPercentage,
}: {
  completedPercentage: number;
}) {
  const state =
    completedPercentage >= COMPLETION_RATE_THRESHOLD ? "green" : "red";

  const stateMap = {
    green: {
      label: "On Track",
      className: "bg-white text-body-text",
    },
    amber: {
      label: "In Progress",
      className: "bg-[#A79A6D] text-white",
    },
    red: {
      label: "At Risk",
      className: "bg-red text-white",
    },
    default: {
      label: "No Data",
      className: "bg-[#A7BDAF] text-white",
    },
  };

  return (
    <div
      className={`px-2.5 py-1 ${stateMap[state].className} text-xs rounded-full flex flex-row items-center gap-1`}
    >
      <div className="w-3 h-3">
        <EngagementCircle
          completionPercentage={completedPercentage}
          pill={true}
        />
      </div>{" "}
      {stateMap[state].label}
    </div>
  );
}
