import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LifecycleStageType } from "../__generated__/graphql";
import { SessionContext } from "../SessionContext";
import { formatStats } from "../lib/behaviors";
import EmptyState from "../patterns/EmptyState";
import CampaignEmpty from "../patterns/illustrations/CampaignEmpty";
import SectionHeader from "../patterns/SectionHeader";
import EnteredCompletedLineChart from "./EnteredCompletedLineChart";
import StatsHeading from "../patterns/StatsHeading";

export default function Overview({
  lifecycleStage,
}: {
  lifecycleStage: LifecycleStageType;
}) {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const behavior = lifecycleStage?.behavior;
  const behaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );
  const hasData = behavior.stats.entered.count > 0;
  return (
    <>
      {hasData ? (
        <div
          className="flex flex-row gap-2 mx-8 bg-pavlov-bg-light rounded-lg shadow-sm hover:shadow-md animate cursor-pointer"
          onClick={() => {
            navigate(`/lifecycle_stages/${lifecycleStage.id}?view=overview`);
          }}
        >
          <div className="flex flex-col flex-grow p-6 pr-0">
            <div className="text-md font-bold -mb-8">
              <SectionHeader
                title={behavior.name}
                description="Total completed"
                size="large"
              />
            </div>
            <EnteredCompletedLineChart lifecycleStageBehavior={behavior} />
          </div>
          <div className="flex flex-col bg-pavlov-bg-lighter px-8 py-4 justify-between">
            <div className="flex flex-grow border-b-1 border-grey-300 border-dashed">
              <div className="my-auto">
                <StatsHeading
                  title="Avg Time to Completion"
                  stat={behaviorStats.averageTimeToCompletion}
                  subtitle="days"
                />
              </div>
            </div>
            <div className="flex flex-grow align-middle border-b-1 border-grey-300 border-dashed">
              <div className="my-auto">
                <StatsHeading
                  title="Total Completed"
                  stat={behaviorStats.completed.count.toString()}
                  subtitle={`(${behaviorStats.completed.percentage})`}
                />
              </div>
            </div>
            <div className="flex flex-grow align-middle">
              <div className="my-auto">
                <StatsHeading
                  title="Total Pending"
                  stat={behaviorStats.observing.count.toString()}
                  subtitle={`(${behaviorStats.observing.percentage})`}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mx-8 bg-pavlov-bg-light p-6 rounded-lg">
          <div className="text-md font-bold -mb-8">
            <SectionHeader title={lifecycleStage.name} size="large" />
          </div>
          <EmptyState
            title="No Data"
            description="No data available for this Lifecycle Stage"
            icon={<CampaignEmpty />}
          />
        </div>
      )}
    </>
  );
}
