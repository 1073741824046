import React, { Dispatch, useCallback, useState } from "react";

import Audience from "./drawer/audience";
import { CampaignDefinitionEditAction } from "./reducer";
import { CampaignDefinition } from "./types";
import {
  GetCampaignQuery,
  IncentiveType,
  MessagingSenderType,
  TargetMemberTypeChoices,
  MachineStatusChoices,
} from "../__generated__/graphql";
import Drawer from "../patterns/Drawer";
import Engagement from "./drawer/engagement";
import FollowUp from "./drawer/follow-up";
import Exit from "./drawer/exit";
import Alert from "../patterns/Alert";
import { useBlocker } from "react-router-dom";
import Modal from "../patterns/Modal";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import CompletionCriteriaEditor from "./drawer/CompletionCriteriaEditor";
import AutoExit from "./drawer/AutoExit";
import AudienceTargetingSection from "./builder_sections/AudienceTargetingSection";
import IncentiveAndEngagementSection from "./builder_sections/IncentiveAndEngagementSection";
import CompletionCriteriaSection from "./builder_sections/CompletionCriteriaSection";
import AutoExitSection from "./builder_sections/AutoExitSection";
import FollowUpActionsSection from "./builder_sections/FollowUpActionsSection";
import ExitActionsSection from "./builder_sections/ExitActionsSection";
import SubSectionHeader from "../patterns/SubSectionHeader";

interface Props {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  data: GetCampaignQuery;
  campaignDefinition: CampaignDefinition;
}

export interface DrawerComponentProps {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  campaignId: string;
  campaignConfig: CampaignDefinition;
  targetMemberType: TargetMemberTypeChoices;
  incentives: Omit<IncentiveType, "config">[];
  senders?: MessagingSenderType[];
  campaignStatus: MachineStatusChoices;
  entityName: string;
}

type DrawerComponent = typeof Audience | typeof Engagement | typeof FollowUp;

export default function CampaignForm({
  dispatch,
  data,
  campaignDefinition,
}: Props) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const entityName = data.campaignById.isBehavior ? "behavior" : "campaign";

  const isDirty =
    JSON.stringify(campaignDefinition) !==
    JSON.stringify(JSON.parse(data?.campaignById?.definition || "[]"));

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  if (!campaignDefinition || !data) {
    return;
  }

  const isComplete = data.campaignById.status === MachineStatusChoices.Complete;

  return (
    <>
      {blocker.state === "blocked" ? (
        <Modal title="Unsaved changes" close={closeDrawer}>
          <p className="text-sm">
            You have unsaved changes, are you sure you want to exit?
          </p>
          <div className="flex gap-4 justify-center mt-4">
            <SecondaryButton
              label="Exit and lose changes"
              onClick={() => blocker.proceed()}
            />
            <PrimaryButton label="Cancel" onClick={() => blocker.reset()} />
          </div>
        </Modal>
      ) : null}
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            campaignId={data.campaignById.id}
            campaignConfig={campaignDefinition}
            incentives={data.incentives}
            senders={data.messagingSenders}
            targetMemberType={data.campaignById.targetMemberType}
            campaignStatus={data.campaignById.status}
            entityName={entityName}
          />
        </Drawer>
      )}
      {isComplete && (
        <div className="mx-8 mb-6 ">
          <Alert
            message={`You can't edit a completed ${entityName}`}
            type="warning"
          />
        </div>
      )}
      <div className="flex grow flex-col mx-8 mb-8 gap-y-6">
        <section className="flex flex-col gap-2">
          <SubSectionHeader label="Behavior" />
          <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
            <CompletionCriteriaSection
              data={data}
              campaignDefinition={campaignDefinition}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: CompletionCriteriaEditor,
                });
              }}
            />

            <AudienceTargetingSection
              data={data}
              campaignDefinition={campaignDefinition}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: Audience,
                });
              }}
            />
            {!data.campaignById.isBehavior && (
              <AutoExitSection
                data={data}
                campaignDefinition={campaignDefinition}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: AutoExit,
                  });
                }}
              />
            )}
          </div>
        </section>
        {!data.campaignById.isBehavior && (
          <>
            <section className="flex flex-col gap-2">
              <SubSectionHeader label="Intervention" />
              <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
                <IncentiveAndEngagementSection
                  data={data}
                  campaignDefinition={campaignDefinition}
                  openDrawer={() => {
                    setDrawerIsVisible(true);
                    setDrawerComponent({
                      Component: Engagement,
                    });
                  }}
                />
              </div>
            </section>
            <section className="flex flex-col gap-2">
              <SubSectionHeader label="Follow-up Actions" />

              <div className="flex flex-row gap-4">
                <div className="flex basis-0 grow bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6">
                  <FollowUpActionsSection
                    data={data}
                    campaignDefinition={campaignDefinition}
                    openDrawer={() => {
                      setDrawerIsVisible(true);
                      setDrawerComponent({
                        Component: FollowUp,
                      });
                    }}
                  />
                </div>
                <div className="flex basis-0 grow bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6">
                  <ExitActionsSection
                    data={data}
                    campaignDefinition={campaignDefinition}
                    openDrawer={() => {
                      setDrawerIsVisible(true);
                      setDrawerComponent({
                        Component: Exit,
                      });
                    }}
                  />
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
}
