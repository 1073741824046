import { useState, useContext } from "react";
import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";

import { formatPercentage } from "../../lib/number";
import { Tabs } from "../lib";
import { cx } from "../../lib/cx";
import { SessionContext } from "../../SessionContext";
import Line from "../../patterns/charts/Line";
import EmptyState from "../../patterns/EmptyState";
import BehaviorsEmpty from "../../patterns/illustrations/BehaviorsEmpty";
import StatsHeading from "../../patterns/StatsHeading";

interface Props {
  behavior: BehaviorType;
  setActiveTab: (tab: Tabs) => void;
}

export default function TabsAndGraph({ behavior, setActiveTab }: Props) {
  const [currentTab, setCurrentTab] = useState(0);
  const session = useContext(SessionContext);

  const target =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";

  const enteredDenominator =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? session.orgCount
      : session.peopleCount;
  const entered = behavior.stats.entered.count;
  const enteredPercentage = formatPercentage(entered, enteredDenominator);
  const completed = behavior.stats.completed.count;
  const completedPercentage = formatPercentage(completed, entered);
  const averageTimeToCompletion = `${behavior.stats.timeToCompletion.average.toFixed(2)}`;
  const observing = behavior.stats.observing.count;
  const observingPercentage = formatPercentage(observing, entered);
  const dataPoints = [
    {
      id: 0,
      title: `Entered ${target}`,
      value: entered,
      percentage: enteredPercentage
        ? `${enteredPercentage} of all ${target}`
        : "-",
      current: true,
      tab: Tabs.Eligible,
      emptyStateDescription: `No ${target} have yet to meet the audience criteria for this stage.`,
    },
    {
      id: 1,
      title: `Total completed`,
      value: completed,
      percentage: completedPercentage
        ? `${completedPercentage} of all entrants`
        : "-",
      current: false,
      tab: Tabs.Completed,
      emptyStateDescription: `No ${target} have yet to complete this stage.`,
    },
    {
      id: 2,
      title: `Avg completion time`,
      value: averageTimeToCompletion,
      percentage: "days",
      current: false,
      tab: Tabs.TimeToCompletion,
      emptyStateDescription: `No ${target} have yet to complete this stage.`,
    },
    {
      id: 3,
      title: `Total Pending`,
      value: observing,
      percentage: observingPercentage
        ? `${observingPercentage} of all entrants`
        : "-",
      current: true,
      tab: Tabs.Observing,
      emptyStateDescription: `No entered ${target} have failed to complete this stage.`,
    },
  ];
  const graphdata = {
    0: {
      data: [{ id: 1, data: behavior.stats.entered.timeSeries }],
      type: "time",
    },
    1: {
      data: [{ id: 1, data: behavior.stats.completed.timeSeries }],
      type: "time",
    },
    2: {
      data: [{ id: 1, data: behavior.stats.timeToCompletion.plot }],
      type: "linear",
      valueSuffix: "day",
      markers: [
        {
          axis: "x",
          legend: "Average",
          lineStyle: {
            stroke: "#8D67FC",
            strokeWidth: 2,
            strokeDasharray: 8,
          } as Partial<React.CSSProperties>,
          textStyle: {
            fontSize: 12,
            fill: "#1E293B",
          } as Partial<React.CSSProperties>,
          value: behavior.stats.timeToCompletion.average,
        },
      ],
    },
    3: {
      data: [{ id: 1, data: behavior.stats.observing.timeSeries }],
      type: "time",
    },
  };
  const hasData = !!graphdata[currentTab].data[0].data.length;
  return (
    <div className="flex flex-col rounded-xl mx-8 mb-4 shadow-sm">
      <div className="grid grid-cols-4 rounded-t-xl overflow-hidden">
        {dataPoints.map((item) => (
          <div
            className={cx(
              currentTab === item.id
                ? "bg-pavlov-bg"
                : "animate bg-pavlov-bg-lighter hover:bg-pavlov-bg-dark",
              "flex cursor-pointer flex-col border-r-1 border-white p-4 last:border-r-0",
            )}
            key={item.id}
            onClick={() => {
              setCurrentTab(item.id);
              setActiveTab(item.tab);
            }}
          >
            <div className="flex flex-col">
              <StatsHeading
                title={item.title}
                stat={item.value}
                subtitle={item.percentage}
              />
            </div>
          </div>
        ))}
      </div>
      {hasData ? (
        <div className="h-72 w-auto bg-pavlov-bg px-4 py-4 rounded-b-lg">
          <Line
            data={graphdata[currentTab].data}
            type={graphdata[currentTab].type}
            valueSuffix={graphdata[currentTab].valueSuffix}
            markers={graphdata[currentTab].markers}
          />
        </div>
      ) : (
        <div className="w-auto bg-pavlov-bg px-4 py-4 rounded-b-lg">
          <EmptyState
            title="No data"
            description={dataPoints[currentTab].emptyStateDescription}
            icon={<BehaviorsEmpty />}
            fullHeight={true}
          ></EmptyState>
        </div>
      )}
    </div>
  );
}
