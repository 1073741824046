import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BehaviorType, Flags } from "../../__generated__/graphql";
import { formatStats } from "../../lib/behaviors";
import { SessionContext } from "../../SessionContext";
import EmptyState from "../../patterns/EmptyState";
import BehaviorsEmpty from "../../patterns/illustrations/BehaviorsEmpty";
import CompletedLineChart from "../graphs/CompletedLineChart";
import SectionHeader from "../../patterns/SectionHeader";
import useIsFeatureEnabled from "../../hooks/useIsFeatureEnabled";
import StatsHeading from "../../patterns/StatsHeading";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";

export default function Objective({
  lifecycleStageId,
  behavior,
  subBehaviors,
  onCreateCampaign,
}: {
  lifecycleStageId: string;
  behavior: BehaviorType;
  subBehaviors?: BehaviorType[];
  onCreateCampaign?: (b: BehaviorType) => void;
}) {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const behaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );
  const hasData = behaviorStats.entered.count > 0;

  const objectivePageUrl = useIsFeatureEnabled(Flags.CanViewNewObjectivePage)
    ? `/lifecycle_stages/${lifecycleStageId}/objectives/${behavior.id}?view=overview`
    : `/behaviors/${behavior.id}?view=overview`;

  return (
    <>
      {hasData ? (
        <div className="mb-8">
          <div
            className="flex w-full overflow-hidden rounded-xl mb-2 shadow-sm cursor-pointer hover:shadow-md animate"
            onClick={() => {
              navigate(objectivePageUrl);
            }}
          >
            <div className="flex flex-col flex-grow bg-pavlov-bg-light">
              <div className="px-6 pt-6 flex flex-row gap-x-2 -mb-8 justify-between">
                <SectionHeader
                  title={behavior.name}
                  description="Total completed"
                  size="large"
                />
                {onCreateCampaign && (
                  <div className="">
                    {" "}
                    <PrimaryButton
                      size="sm"
                      label="Create a Campaign"
                      onClick={() => onCreateCampaign(behavior)}
                    />
                  </div>
                )}
              </div>
              <div className="">
                <CompletedLineChart
                  behavior={behavior}
                  subBehaviors={subBehaviors}
                />
              </div>
            </div>
            <div className="flex flex-col bg-pavlov-bg-lighter px-8 py-4 justify-between">
              <div className="flex flex-grow border-b-1 border-grey-300 border-dashed">
                <div className="my-auto">
                  <StatsHeading
                    title="Avg Time to Completion"
                    stat={behaviorStats.averageTimeToCompletion}
                    subtitle="days"
                  />
                </div>
              </div>
              <div className="flex flex-grow align-middle border-b-1 border-grey-300 border-dashed">
                <div className="my-auto">
                  <StatsHeading
                    title="Total Completed"
                    stat={behaviorStats.completed.count.toString()}
                    subtitle={`(${behaviorStats.completed.percentage})`}
                  />
                </div>
              </div>
              <div className="flex flex-grow align-middle">
                <div className="my-auto">
                  <StatsHeading
                    title="Total Pending"
                    stat={behaviorStats.observing.count.toString()}
                    subtitle={`(${behaviorStats.observing.percentage})`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-pavlov-bg-light p-6 mb-6 rounded-lg">
          <div className="text-md font-bold mb-4">{behavior.name}</div>
          <EmptyState
            title="No Data"
            description="No data available for this Lifecycle Stage"
            icon={<BehaviorsEmpty />}
            fullHeight={false}
          ></EmptyState>
        </div>
      )}
    </>
  );
}
