import { useContext } from "react";
import { BehaviorType } from "../../__generated__/graphql";
import { TrophyIcon } from "@heroicons/react/24/outline";
import { formatStats } from "../../lib/behaviors";
import { SessionContext } from "../../SessionContext";
import EmptyState from "../../patterns/EmptyState";
import BehaviorsEmpty from "../../patterns/illustrations/BehaviorsEmpty";

import SectionHeader from "../../patterns/SectionHeader";
import CompletedLineChart from "./CompletedLineChart";

export default function Objective({
  behavior,
  onCreateCampaign,
}: {
  behavior: BehaviorType;
  onCreateCampaign?: (b: BehaviorType) => void;
}) {
  const session = useContext(SessionContext);
  const behaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );

  const hasData = behaviorStats.entered.count > 0;

  return (
    <>
      {hasData ? (
        <div className="mb-8">
          <div className="flex w-full overflow-hidden rounded-xl mb-2 shadow-sm">
            <div className="flex flex-col flex-[3] bg-grey-80">
              <div className="pl-6 pt-5 text-md font-bold flex flex-row gap-x-2 -mb-8">
                <SectionHeader
                  title={behavior.name}
                  description="Total completed"
                  size="large"
                />
                {onCreateCampaign && (
                  <TrophyIcon
                    className="w-4 h-4 mt-1 cursor-pointer"
                    onClick={() => onCreateCampaign(behavior)}
                  />
                )}
              </div>
              <div className="p-3">
                <CompletedLineChart behavior={behavior} subBehaviors={[]} />
              </div>
            </div>
            <div className="flex flex-col flex-1 bg-grey-50 py-2">
              <div className="h-full border-b-1 border-grey-300 border-dashed py-4 mx-4">
                <div className="text-body-text-lightest">
                  <div className="text-xs">Avg Time to Completion:</div>
                  <div className="text-2xl font-bold my-2 text-body-text">
                    {behaviorStats.averageTimeToCompletion}
                  </div>
                  <div className="text-xs">days</div>
                </div>
              </div>
              <div className="h-full border-b-1 border-grey-300 border-dashed py-4 mx-4">
                <div className="text-body-text-lightest">
                  <div className="text-xs">Total Completed:</div>
                  <div className="text-2xl font-bold my-2 text-body-text">
                    {behaviorStats.completed.count.toString()}
                  </div>
                  <div className="text-xs">
                    ({behaviorStats.completed.percentage})
                  </div>
                </div>
              </div>
              <div className="h-full py-4 mx-4">
                <div className="text-body-text-lightest">
                  <div className="text-xs">Total Pending:</div>
                  <div className="text-2xl font-bold my-2 text-body-text">
                    {behaviorStats.observing.count.toString()}
                  </div>
                  <div className="text-xs">
                    ({behaviorStats.observing.percentage})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-grey-50 p-6 mb-6 rounded-lg">
          <div className="text-md font-bold mb-4">{behavior.name}</div>
          <EmptyState
            title="No Data"
            description="No data available for this Lifecycle Stage"
            icon={<BehaviorsEmpty />}
            fullHeight={false}
          ></EmptyState>
        </div>
      )}
    </>
  );
}
