/* This component should be used to label the start of a page. It should be used against the background colour */

import { useState } from "react";
import { cx } from "../lib/cx";
import {
  PencilSquareIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PrimaryButton from "./atoms/PrimaryButton";
import SecondaryButton from "./atoms/SecondaryButton";
import BehaviorButton from "./atoms/BehaviorButton";
import CampaignButton from "./atoms/CampaignButton";
import LifecycleStageButton from "./atoms/LifecycleStageButton";
import IconButton, { iconMap } from "./atoms/IconButton";

export interface Button {
  label: string;
  isDisabled?: boolean;
  icon?: keyof typeof iconMap;
  type:
    | "primary"
    | "secondary"
    | "behavior"
    | "campaign"
    | "icon"
    | "lifecycle_stage";
  action: () => void;
}

interface Props {
  header: string;
  headerPill?: React.ReactNode;
  tags?: string[];
  subhead?: string;
  slug?: string;
  buttons?: Button[];
  xPadding?: boolean;
  updateHeader?: (headerValue: string) => void;
}

export default function PageHeader({
  header,
  headerPill,
  tags,
  subhead,
  slug,
  buttons = [],
  xPadding = true,
  updateHeader,
}: Props) {
  const [isEditing, setEditing] = useState(false);
  const [headerValue, setHeaderValue] = useState(header);

  return (
    <div className={cx("flex pt-6 pb-4", xPadding !== false && "px-8")}>
      <div className="flex-1">
        <div className="flex">
          {isEditing ? (
            <div className="flex items-center">
              <input
                type="text"
                defaultValue={header}
                onChange={(e) => setHeaderValue(e.target.value)}
                className={cx(
                  "text-sm font-semibold tracking-tighter border border-rule-color rounded-md",
                  headerPill && "mr-1",
                )}
              />
              <div className="mr-1">
                <button
                  className="btn-md animate text-body-text bg-grey-100 hover:bg-blue-100 hover:text-blue-600 hover:border-blue-600 focus:outline-none focus:ring-2 focus:ring-grey-300"
                  onClick={() => {
                    updateHeader(headerValue);
                    setEditing(false);
                  }}
                >
                  <CheckCircleIcon className="w-5 h-5" />
                </button>
              </div>
              <div className="mr-2">
                <button
                  className="btn-md animate text-body-text bg-grey-100 hover:bg-red-100 hover:text-red-600 hover:border-red-600 focus:outline-none focus:ring-2 focus:ring-grey-300"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  <XCircleIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          ) : (
            <>
              <h1
                className={cx("text-xl tracking-tight", headerPill && "mr-2")}
              >
                {header}
              </h1>
              {updateHeader && (
                <PencilSquareIcon
                  className="h-5 w-5 mr-2 mt-1 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setEditing(true)}
                />
              )}
            </>
          )}

          {headerPill}
        </div>
        {subhead || slug ? (
          <div className="text-sm py-1 text-body-text-lighter">
            {subhead && <>{subhead}</>}
            {subhead && slug && <span className="text-grey-400"> | </span>}
            {slug && <>{slug}</>}
          </div>
        ) : null}
        {tags && (
          <div className="mt-2 flex flex-row items-center">
            <span className="text-xs">Tags:</span>
            {tags.map((t) => (
              <span
                key={t}
                className="mx-2 inline-flex items-center rounded-full bg-blue-100 px-1.5 py-0.5 pr-2 text-xs font-medium text-blue-700"
              >
                {t}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="flex">
        {buttons.map((b) => (
          <div key={b.label} className="ml-2">
            {b.type === "primary" ? (
              <PrimaryButton
                label={b.label}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : b.type === "secondary" ? (
              <SecondaryButton
                label={b.label}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : b.type === "behavior" ? (
              <BehaviorButton
                label={b.label}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : b.type === "campaign" ? (
              <CampaignButton
                label={b.label}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : b.type === "lifecycle_stage" ? (
              <LifecycleStageButton
                label={b.label}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : b.type === "icon" ? (
              <IconButton
                icon={b.icon}
                onClick={b.action}
                isDisabled={b.isDisabled}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
