import { useQuery } from "@apollo/client";
import { useMemo, useContext } from "react";
import { MachineStatusChoices } from "../__generated__/graphql";
import { GET_LIFECYCLE_STAGES } from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import LifecycleStageBreakdown from "./LifecycleStageBreakdown";
import Alert from "../patterns/Alert";
import { SessionContext } from "../SessionContext";
import PageHeader from "../patterns/PageHeader";

export const VIEW_PARAM = "view";
export const COMPLETION_RATE_THRESHOLD = 70;

export default function AiAgentDrawer({ close }: { close: () => void }) {
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGES, {
    fetchPolicy: "cache-and-network",
  });
  const sessionContext = useContext(SessionContext);

  const name = useMemo(() => {
    if (!sessionContext.userProfile?.name) {
      return "Agent";
    }
    const [firstName] = sessionContext.userProfile.name.split(" ");
    return firstName;
  }, [sessionContext.userProfile?.name]);

  const lifecycleStages = data?.allLifecycleStages;
  const activeLifecycleStages = useMemo(() => {
    return lifecycleStages?.filter((stage) => {
      const isLive = stage.behavior.status === MachineStatusChoices.Live;
      const hasObjectives = stage.objectiveBehaviors.length > 0;
      const hasEntrants = stage.behavior.stats.entered.count > 0;
      return isLive && hasEntrants && hasObjectives;
    });
  }, [lifecycleStages]);
  const hasActiveLifecycleStages = activeLifecycleStages?.length > 0;

  if (error) return <FullScreenError />;

  return (
    <>
      <div className="flex flex-col">
        <PageHeader
          header={`Welcome back${name && ", " + name}`}
          subhead="All agents are active"
        />
      </div>

      {loading ? (
        <SkeletonScreen label="Talking to Agents..." />
      ) : (
        <>
          {hasActiveLifecycleStages ? (
            <div className="flex flex-col gap-4">
              {activeLifecycleStages.map((lifecycleStage) => (
                <LifecycleStageBreakdown
                  lifecycleStage={lifecycleStage}
                  close={close}
                />
              ))}
            </div>
          ) : (
            <div className="px-8">
              <Alert
                type="success"
                message="No updates worth your attention right now, go get yourself a snack"
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
