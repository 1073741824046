import { useState, useEffect, useMemo, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SCHEDULED_SUMMARY, GET_SLACK_CHANNELS } from "../graphql/queries";
import {
  CREATE_SCHEDULED_SUMMARY,
  UPDATE_SCHEDULED_SUMMARY,
  DELETE_SCHEDULED_SUMMARY,
} from "../graphql/mutations";
import SkeletonScreen from "../patterns/Skeleton";
import BuilderSectionHeader from "../campaign_form/builder_sections/builder-section-header";
import SelectInput from "../patterns/forms/SelectInput";
import TimeInput from "./TimeInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import Toggle from "../patterns/Toggle";
import {
  ScheduledSummaryConfigType,
  ScheduledSummaryScheduleType,
  StateSummaryConfigType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import toast from "react-hot-toast";

const DAYS_OF_THE_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DEFAULT_STATE_SUMMARY_CONFIG = {
  countPrefix: " - Total from the last 7 days:",
  listPrefix: " - {n} most recent {member_type}:",
  listItemTemplate: "    - {{person.name}} ({{person.email}})",
  includeCount: true,
  includeList: true,
};

const DEFAULT_SCHEDULED_SUMMARY = {
  id: null,
  config: {
    slack: {
      channelId: null,
    },
    statesToSummarize: [],
  },
  schedule: {
    hour: 9,
    minute: 5,
    dayOfWeek: 1,
  },
};

function validateTime(hours: number, minutes: number) {
  return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
}

function isScheduleDirty(
  prev: ScheduledSummaryScheduleType,
  current: ScheduledSummaryScheduleType,
) {
  if (!prev || !current) {
    return false;
  }
  return (
    prev.hour !== current.hour ||
    prev.minute !== current.minute ||
    prev.dayOfWeek !== current.dayOfWeek
  );
}

function isConfigDirty(
  prev: ScheduledSummaryConfigType,
  current: ScheduledSummaryConfigType,
) {
  if (!prev || !current) {
    return false;
  }
  return (
    prev.slack.channelId !== current.slack.channelId ||
    prev.statesToSummarize !== current.statesToSummarize
  );
}

export default function ScheduledSummary({
  objectId,
  summaryStateChoices,
  memberType,
}: {
  objectId: string;
  summaryStateChoices: Array<{ value: string; label: string }>;
  memberType: TargetMemberTypeChoices;
}) {
  const { data: summaryData, loading: summaryLoading } = useQuery(
    GET_SCHEDULED_SUMMARY,
    {
      variables: { objectId },
      fetchPolicy: "cache-and-network",
    },
  );
  const [createScheduledSummaryMutation] = useMutation(
    CREATE_SCHEDULED_SUMMARY,
    {
      refetchQueries: [GET_SCHEDULED_SUMMARY],
    },
  );
  const [updateScheduledSummaryMutation] = useMutation(
    UPDATE_SCHEDULED_SUMMARY,
    {
      refetchQueries: [GET_SCHEDULED_SUMMARY],
    },
  );
  const [deleteScheduledSummaryMutation] = useMutation(
    DELETE_SCHEDULED_SUMMARY,
    {
      refetchQueries: [GET_SCHEDULED_SUMMARY],
    },
  );
  const { data: slackChannelsData, loading: slackChannelsLoading } =
    useQuery(GET_SLACK_CHANNELS);
  const [slackChannelId, setSlackChannelId] = useState<string | null>(null);
  const [scheduledHour, setScheduledHour] = useState<number | null>(
    DEFAULT_SCHEDULED_SUMMARY.schedule.hour,
  );
  const [scheduledMinute, setScheduledMinute] = useState<number | null>(
    DEFAULT_SCHEDULED_SUMMARY.schedule.minute,
  );
  const [scheduledDayOfWeek, setScheduledDayOfWeek] = useState<number | null>(
    DEFAULT_SCHEDULED_SUMMARY.schedule.dayOfWeek,
  );
  const [selectedSummaryStates, setSelectedSummaryStates] = useState([]);

  useEffect(() => {
    if (summaryData?.scheduledSummaryByObjectId && slackChannelsData) {
      setSlackChannelId(
        summaryData.scheduledSummaryByObjectId.config.slack.channelId,
      );
      setScheduledHour(summaryData.scheduledSummaryByObjectId.schedule.hour);
      setScheduledMinute(
        summaryData.scheduledSummaryByObjectId.schedule.minute,
      );
      setScheduledDayOfWeek(
        summaryData.scheduledSummaryByObjectId.schedule.dayOfWeek,
      );
      setSelectedSummaryStates(
        summaryData.scheduledSummaryByObjectId.config.statesToSummarize,
      );
    }
  }, [
    summaryData,
    slackChannelsData,
    setSlackChannelId,
    setScheduledHour,
    setScheduledMinute,
  ]);

  const createScheduledSummary = useCallback(async () => {
    const response = await createScheduledSummaryMutation({
      variables: {
        objectId,
        config: {
          slack: {
            channelId: slackChannelId,
          },
          statesToSummarize: selectedSummaryStates.map(
            (s: StateSummaryConfigType) => ({
              stateName: s.stateName,
              countPrefix: s.countPrefix,
              listPrefix: s.listPrefix,
              listItemTemplate: s.listItemTemplate,
              includeCount: s.includeCount,
              includeList: s.includeList,
            }),
          ),
        },
        schedule: {
          hour: scheduledHour,
          minute: scheduledMinute,
          dayOfWeek: scheduledDayOfWeek,
        },
      },
    });
    if (response.data?.createScheduledSummary?.ok) {
      toast.success("Scheduled summary created");
    } else {
      let msg = "Failed to create scheduled summary";
      if (response.errors) {
        msg += ": " + response.errors[0].message;
      }
      toast.error(msg);
    }
  }, [
    objectId,
    scheduledHour,
    scheduledMinute,
    scheduledDayOfWeek,
    slackChannelId,
    selectedSummaryStates,
    createScheduledSummaryMutation,
  ]);

  const updateScheduledSummary = useCallback(async () => {
    const response = await updateScheduledSummaryMutation({
      variables: {
        objectId,
        config: {
          slack: {
            channelId: slackChannelId,
          },
          statesToSummarize: selectedSummaryStates.map(
            (s: StateSummaryConfigType) => ({
              stateName: s.stateName,
              countPrefix: s.countPrefix,
              listPrefix: s.listPrefix,
              listItemTemplate: s.listItemTemplate,
              includeCount: s.includeCount,
              includeList: s.includeList,
            }),
          ),
        },
        schedule: {
          hour: scheduledHour,
          minute: scheduledMinute,
          dayOfWeek: scheduledDayOfWeek,
        },
      },
    });
    if (response.data?.updateScheduledSummary?.ok) {
      toast.success("Scheduled summary updated");
    } else {
      let msg = "Failed to update scheduled summary";
      if (response.errors) {
        msg += ": " + response.errors[0].message;
      }
      toast.error(msg);
    }
  }, [
    objectId,
    scheduledHour,
    scheduledMinute,
    scheduledDayOfWeek,
    slackChannelId,
    selectedSummaryStates,
    updateScheduledSummaryMutation,
  ]);

  const deleteScheduledSummary = useCallback(async () => {
    const response = await deleteScheduledSummaryMutation({
      variables: {
        objectId,
      },
    });
    if (response.data?.deleteScheduledSummary?.ok) {
      toast.success("Scheduled summary deleted");
      setSlackChannelId(null);
      setScheduledHour(DEFAULT_SCHEDULED_SUMMARY.schedule.hour);
      setScheduledMinute(DEFAULT_SCHEDULED_SUMMARY.schedule.minute);
      setScheduledDayOfWeek(DEFAULT_SCHEDULED_SUMMARY.schedule.dayOfWeek);
      setSelectedSummaryStates([]);
    } else {
      let msg = "Failed to delete scheduled summary";
      if (response.errors) {
        msg += ": " + response.errors[0].message;
      }
      toast.error(msg);
    }
  }, [objectId, deleteScheduledSummaryMutation]);

  const validTime = useMemo(
    () => validateTime(scheduledHour, scheduledMinute),
    [scheduledHour, scheduledMinute],
  );

  const summary = useMemo(
    () => summaryData?.scheduledSummaryByObjectId || DEFAULT_SCHEDULED_SUMMARY,
    [summaryData],
  );

  if (summaryLoading || slackChannelsLoading) {
    return <SkeletonScreen />;
  }

  const hasSelectedSlackChannel = slackChannelId !== null;
  const hasSelectedTimeAndDay =
    scheduledHour !== null &&
    scheduledMinute !== null &&
    scheduledDayOfWeek !== null;
  const hasSelectedSummaryStates = selectedSummaryStates.length > 0;

  const isDirty =
    isScheduleDirty(summary?.schedule, {
      hour: scheduledHour,
      minute: scheduledMinute,
      dayOfWeek: scheduledDayOfWeek,
    }) ||
    isConfigDirty(summary?.config, {
      slack: {
        channelId: slackChannelId,
      },
      statesToSummarize: selectedSummaryStates,
    });

  const canUpdate =
    hasSelectedSlackChannel &&
    hasSelectedTimeAndDay &&
    hasSelectedSummaryStates &&
    validTime &&
    isDirty;

  const memberTypeLabel =
    memberType === TargetMemberTypeChoices.Org ? "organizations" : "people";

  return (
    <div className="flex grow flex-col mx-8 mb-8 gap-y-6">
      <section className="flex flex-col gap-2">
        {/* <SubSectionHeader label="Entry and Completion Criteria" /> */}
        <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
          <div>
            <BuilderSectionHeader
              label="Destination"
              description="Where should the summary be sent?"
              isValid={hasSelectedSlackChannel}
            />
            <div className="px-7 max-w-md">
              <SelectInput
                label={""}
                description={""}
                placeholder="Pick a slack channel"
                required={true}
                value={slackChannelId}
                options={slackChannelsData.slackChannels.map((s) => ({
                  label: s.name,
                  value: s.id,
                }))}
                disabled={false}
                onChange={(s) => setSlackChannelId(s)}
              />
            </div>
          </div>
          <div className="-mt-4">
            {" "}
            {/* This is custom to remove the padding that's added to the input above  */}
            <BuilderSectionHeader
              label="Schedule"
              description="When should the summary be sent?"
              isValid={hasSelectedTimeAndDay}
            />
            <div className="px-7 flex flex-row gap-2 items-center text-body-text-lightest text-xs">
              <div>Every</div>
              <div>
                <select
                  id="select-input__select"
                  value={scheduledDayOfWeek}
                  className="block w-full rounded-lg border border-rule-color bg-white text-xs font-normal leading-6 tracking-wide text-body-text focus:border-blue-800 focus:ring-blue-300"
                  onChange={(e) => {
                    setScheduledDayOfWeek(Number(e.target.value));
                  }}
                >
                  {DAYS_OF_THE_WEEK.map((d, idx) => (
                    <option
                      key={d}
                      value={idx}
                      className="text-xs font-normal leading-4 tracking-wide"
                    >
                      {d}
                    </option>
                  ))}
                </select>
              </div>
              <div>at</div>
              <div>
                <TimeInput
                  hours={scheduledHour}
                  minutes={scheduledMinute}
                  onHoursChange={(hours) => setScheduledHour(hours)}
                  onMinutesChange={(minutes) => setScheduledMinute(minutes)}
                  validTime={validateTime(scheduledHour, scheduledMinute)}
                />
              </div>
            </div>
          </div>
          <div>
            <BuilderSectionHeader
              label="Content"
              description={`Send summary for ${memberTypeLabel} that have:`}
              isValid={hasSelectedSummaryStates}
            />
            <div className="px-7 gap-2 w-full max-w-md ">
              {summaryStateChoices.map(({ label, value }) => (
                <div className="flex flex-row justify-between py-3 border-b border-rule-color text-xs items-center">
                  <div className="">{label}</div>
                  <Toggle
                    size="sm"
                    isOn={selectedSummaryStates.some(
                      (s) => s.stateName === value,
                    )}
                    onChange={() => {
                      if (
                        selectedSummaryStates.some((s) => s.stateName === value)
                      ) {
                        setSelectedSummaryStates((prevState) =>
                          prevState.filter((s) => s.stateName !== value),
                        );
                      } else {
                        setSelectedSummaryStates((prevState) => [
                          ...prevState,
                          {
                            stateName: value,
                            ...DEFAULT_STATE_SUMMARY_CONFIG,
                          },
                        ]);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className="flex flew-row justify-end gap-1">
        {summary.id ? (
          <>
            <PrimaryButton
              label="Delete Scheduled Summary"
              onClick={deleteScheduledSummary}
              isDisabled={false}
            />
            <PrimaryButton
              label="Update Scheduled Summary"
              onClick={updateScheduledSummary}
              isDisabled={!canUpdate}
            />
          </>
        ) : (
          <PrimaryButton
            label="Create Scheduled Summary"
            onClick={createScheduledSummary}
            isDisabled={!canUpdate}
          />
        )}
      </div>
    </div>
  );
}
