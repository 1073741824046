import { useCallback, useContext, useState } from "react";
import {
  BehaviorStateNames,
  BehaviorType,
  LifecycleStageType,
  OrgType,
  PersonType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";

import { cx } from "../lib/cx";
import { formatPercentage } from "../lib/number";
import { SessionContext } from "../SessionContext";
import Line from "../patterns/charts/Line";
import Drawer from "../patterns/Drawer";
import OrgProfile from "../org/OrgProfile";
import MemberTable from "./overview/MemberTable";
import PageHeader from "../patterns/PageHeader";
import SubObjective from "./overview/SubObjective";
import StatsHeading from "../patterns/StatsHeading";

export default function Overview({
  lifecycleStage,
  behavior,
  subBehaviors,
}: {
  lifecycleStage: LifecycleStageType;
  behavior: BehaviorType;
  subBehaviors: any[];
}) {
  const session = useContext(SessionContext);
  const tabs = ["entered", "observing", "completed", "timeToCompletion"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const target =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";
  const enteredDenominator =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? session.orgCount
      : session.peopleCount;

  const dataPoints = {
    entered: {
      label: "Entered",
      value: behavior.stats.entered.count,
      percentage: `${formatPercentage(
        behavior.stats.entered.count,
        enteredDenominator,
      )} of all ${target}`,
      data: [{ id: 1, data: behavior.stats.entered.timeSeries }],
      type: "time",
      valueSuffix: "",
      markers: [],
      variables: (behavior) => ({ behaviorId: behavior.id }),
    },
    observing: {
      label: "In Progress",
      value: behavior.stats.observing.count,
      percentage: `${formatPercentage(
        behavior.stats.observing.count,
        behavior.stats.entered.count,
      )} of all entrants`,
      data: [{ id: 1, data: behavior.stats.observing.timeSeries }],
      type: "time",
      valueSuffix: "",
      markers: [],
      variables: (behavior) => ({
        behaviorId: behavior.id,
        stateFilter: BehaviorStateNames.Completed,
      }),
    },
    completed: {
      label: "Completed",
      value: behavior.stats.completed.count,
      percentage: `${formatPercentage(
        behavior.stats.completed.count,
        behavior.stats.entered.count,
      )} of all entrants`,
      data: [{ id: 1, data: behavior.stats.completed.timeSeries }],
      type: "time",
      valueSuffix: "",
      markers: [],
      variables: (behavior) => ({
        behaviorId: behavior.id,
        stateFilter: BehaviorStateNames.Observing,
      }),
    },
    timeToCompletion: {
      label: "Avg time to completion",
      value: behavior.stats.timeToCompletion.average.toFixed(2),
      percentage: "days",
      data: [{ id: 1, data: behavior.stats.timeToCompletion.plot }],
      type: "linear",
      valueSuffix: "day",
      markers: [
        {
          axis: "x",
          legend: "Average",
          lineStyle: {
            stroke: "#8D67FC",
            strokeWidth: 2,
            strokeDasharray: 8,
          } as Partial<React.CSSProperties>,
          textStyle: {
            fontSize: 12,
            fill: "#1E293B",
          } as Partial<React.CSSProperties>,
          value: behavior.stats.timeToCompletion.average,
        },
      ],
      variables: (behavior) => ({
        behaviorId: behavior.id,
        stateFilter: BehaviorStateNames.Observing,
      }),
    },
  };

  const hasSubBehaviors = subBehaviors.length > 0;

  return (
    <>
      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            <OrgProfile id={activeMember.id} />
          </div>
        </Drawer>
      )}

      <div className="mx-8 flex flex-row rounded-t-xl overflow-hidden">
        {tabs.map((state) => (
          <div
            key={state}
            className={cx(
              currentTab === state
                ? "bg-pavlov-bg"
                : "animate bg-pavlov-bg-lighter hover:bg-pavlov-bg-dark",
              "flex-1 cursor-pointer flex-col border-r-1 border-white p-4 last:border-r-0",
            )}
            onClick={() => setCurrentTab(state)}
          >
            <StatsHeading
              title={dataPoints[state].label}
              stat={dataPoints[state].value}
              subtitle={`${dataPoints[state].percentage}`}
            />
          </div>
        ))}
      </div>
      <div className="mx-8 h-72 p-4 pr-0 w-auto mb-4 bg-pavlov-bg rounded-b-xl overflow-hidden">
        <Line
          data={dataPoints[currentTab].data}
          type={dataPoints[currentTab].type as "time" | "linear"}
          markers={dataPoints[currentTab].markers}
          valueSuffix={dataPoints[currentTab].valueSuffix}
        />
      </div>
      <MemberTable
        lifecycleStage={lifecycleStage}
        behavior={behavior}
        activeState={
          currentTab as
            | "entered"
            | "observing"
            | "completed"
            | "timeToCompletion"
        }
        showMember={(member) => {
          setActiveMember(member);
          setDrawerIsVisible(true);
        }}
      />
      {hasSubBehaviors && (
        <>
          <PageHeader header={`${behavior.name} Sub Objectives`} subhead={""} />
          <div className="mx-8">
            {subBehaviors.map((behavior) => (
              <SubObjective behavior={behavior} />
            ))}
          </div>
        </>
      )}
    </>
  );
}
