/* This component should be used to divide sections of a page. It should be used against the background colour, above cards.  */

import {
  TagIcon,
  UserGroupIcon,
  PlayIcon,
  CursorArrowRaysIcon,
  ListBulletIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";

import { CheckCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  title: string;
  description?: string;
  icon?: keyof typeof iconMap; // icon is optional and refers to a key in iconMap
  size?: "default" | "large"; // New size prop with "default" as default value
}

export const iconMap = {
  "Personal Attributes": TagIcon,
  "Other members": UserGroupIcon,
  "Active Campaigns": PlayIcon,
  "Finished Campaigns": CheckCircleIcon,
  "Events Summary": CursorArrowRaysIcon,
  Performance: CursorArrowRaysIcon,
  Activity: ListBulletIcon,
  Summary: DocumentTextIcon,
};

export default function SectionHeader({
  title,
  description,
  icon,
  size = "default", // Default value is "default"
}: Props) {
  // const IconComponent = icon && iconMap[icon];

  const titleClasses =
    size === "large" ? "font-semibold tracking-tight" : "text-sm font-medium";
  const descriptionClasses = size === "large" ? "text-xs leading-5" : "text-xs";

  return (
    <div className="flex flex-row gap-x-2">
      {/* {IconComponent && (
        <div className="flex h-4 items-center">
          <IconComponent className="inline-block w-3.5 text-body-text" />
        </div>
      )} */}

      <div className="flex flex-col">
        <div
          className={`text-body-text ${titleClasses} ${!description ? "mb-4" : ""}`}
        >
          {title}
        </div>
        {description && (
          <div
            className={`text-body-text-lighter font-normal ${descriptionClasses} mb-3`}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  );
}
