import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Link, NavLink } from "react-router-dom";
import { GET_ORG } from "../graphql/queries";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import DOMPurify from "dompurify";
import Card from "../patterns/Card";
import { formatLong } from "../lib/date";
import { pluralize } from "../lib/string";
import PageHeader from "../patterns/PageHeader";
import SectionHeader from "../patterns/SectionHeader";
import { CubeIcon, CubeTransparentIcon } from "@heroicons/react/24/outline";
import AttributeCloud from "../attributes/AttributeCloud";
import SkeletonScreen from "../patterns/Skeleton";
import DataSources from "../person/data-sources";
import useFinishedCampaigns from "../hooks/useFinishedCampaigns";
import BehaviorTimelines from "../patterns/charts/BehaviorTimelines";
import MemberTimeline from "../audience/MemberTimeline";
import { TargetMemberTypeChoices } from "../__generated__/graphql";

const sanitizeConfig = {
  ALLOWED_TAGS: ["h1", "h2", "h3", "p", "ul", "ol", "li", "hr"],
  ALLOWED_ATTR: [], // empty array means no attributes allowed
};

interface Props {
  id: string;
}

export default function OrgProfile({ id }: Props) {
  const { data, loading } = useQuery(GET_ORG, {
    variables: { id: id },
  });

  const allFinishedCampaigns = useFinishedCampaigns(
    data?.orgById.completedCampaigns || [],
    data?.orgById.exitedCampaigns || [],
  );

  const lifecycleStage = useMemo(() => {
    return data?.orgById.currentLifecycleStage;
  }, [data?.orgById.currentLifecycleStage]);

  const cohorts = useMemo(() => {
    return data?.orgById.cohorts;
  }, [data?.orgById.cohorts]);

  if (loading) {
    return <SkeletonScreen />;
  }

  return (
    <>
      <div className="flex flex-col">
        <PageHeader
          header={data.orgById.name}
          subhead={pluralize(data.orgById.members.length, "member")}
          buttons={[]}
        />
        {(lifecycleStage || cohorts.length > 0) && (
          <div className="flex flex-row gap-2 mx-8 mb-4">
            {lifecycleStage && (
              <span className="flex">
                <span className="px-3 py-1 rounded-full bg-gray-200 text-xs text-body-text">
                  Lifecycle Stage: {lifecycleStage.name}
                </span>
              </span>
            )}
            {cohorts.map((c) => (
              <span className="flex">
                <span className="px-3 py-1 rounded-full bg-gray-200 text-xs text-body-text">
                  Cohort: {c.name}
                </span>
              </span>
            ))}
          </div>
        )}
        {data.orgById.mostRecentHealthSummary ? (
          <div className="flex flex-col gap-2 mx-8 mb-4">
            <SectionHeader title="Summary" icon="Summary"></SectionHeader>
            <Card>
              <div
                className="text-xs text-body-text ai_health_summary"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    data.orgById.mostRecentHealthSummary.body,
                    sanitizeConfig,
                  ),
                }}
              />
            </Card>
          </div>
        ) : (
          <div className="flex flex-row gap-2 mx-8 mb-4">
            <span className="text-xs text-body-text">
              For health summaries to be generated, the organization must be in
              a lifecycle stage, and a cohort with an enabled periodic summary.
            </span>
          </div>
        )}

        <div className="text-xs text-body-text space-y-8 mx-8">
          <BehaviorTimelines member={data.orgById} />
          <MemberTimeline
            memberId={data.orgById.id}
            targetMemberType={TargetMemberTypeChoices.Org}
          />
          <div className="">
            <SectionHeader
              title="Attributes"
              icon="Personal Attributes"
            ></SectionHeader>
            <Card>
              <AttributeCloud
                attrs={data.orgById.attributes}
                entityId={data.orgById.id}
              />
            </Card>
          </div>
          <div>
            <SectionHeader title="Members" icon="Other members"></SectionHeader>
            <Card>
              {data.orgById.members.length === 0 ? (
                <p className="font-normal">No Members</p>
              ) : (
                <div>
                  {data.orgById.members.map((m, index) => (
                    <span key={m.id}>
                      <Link
                        className="block border-b border-rule-color last:border-0 py-2 first:pt-0 last:pb-0 rounded-sm hover:text-active-blue"
                        to={`/audience/people/${m.id}`}
                      >
                        <div className="flex flex-row items-center gap-x-1.5 text-center">
                          <div className="w-5 h-5 rounded-full">
                            <UserCircleIcon className="w-5 h-5 text-yellow-300" />
                          </div>
                          {m.displayName}
                        </div>
                      </Link>
                    </span>
                  ))}
                </div>
              )}
            </Card>
          </div>

          <div className="">
            <SectionHeader
              title="Active Campaigns"
              icon="Active Campaigns"
            ></SectionHeader>
            <Card>
              <div>
                {data.orgById.activeCampaigns.length === 0 ? (
                  <p className="font-normal">No Active campaigns</p>
                ) : (
                  <div>
                    {data.orgById.activeCampaigns.map((c) => (
                      <div
                        key={c.campaignId}
                        className="flex-row w-full py-1.5 first:pt-0 last:pb-0"
                      >
                        <NavLink
                          to={`/campaigns/${c.campaignId}`}
                          className="flex items-center"
                        >
                          <div className="flex flex-row items-center gap-x-1.5 text-center">
                            <div className="flex w-5 h-5 rounded-full bg-active-blue items-center text-center">
                              <CubeTransparentIcon className="p-1 text-white" />
                            </div>
                            <div className="min-w-44 text-start">
                              {c.campaignName}
                            </div>
                          </div>

                          <div className="mr-6 leading-6 min-w-44">
                            {formatLong(c.date)}
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>

          <div className="">
            <SectionHeader
              title="Finished Campaigns"
              icon="Finished Campaigns"
            ></SectionHeader>
            <Card>
              <div>
                {allFinishedCampaigns.length === 0 ? (
                  <p className="font-normal text-body-text-lightest">
                    No Finished campaigns
                  </p>
                ) : (
                  <div className="flex">
                    {allFinishedCampaigns.map((c) => (
                      <div
                        key={c.campaignId}
                        className="flex-row w-full py-1.5 first:pt-0 last:pb-0"
                      >
                        <NavLink
                          to={`/campaigns/${c.campaignId}`}
                          className="flex items-center"
                        >
                          <div className="flex flex-row items-center gap-x-1.5 text-center">
                            <div className="flex w-5 h-5 rounded-full bg-body-text items-center text-center">
                              <CubeIcon className="p-1 text-white" />
                            </div>
                            <div className="min-w-44 text-start">
                              {c.campaignName}
                            </div>
                          </div>

                          <div className="mr-6 leading-6 min-w-44">
                            {formatLong(c.date)}
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
          {data.orgById.updates.length > 0 && (
            <DataSources updates={data.orgById.updates} />
          )}
        </div>
      </div>
    </>
  );
}
