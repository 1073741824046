import { Link } from "react-router-dom";
import Alert from "../../patterns/Alert";
import { pluralize } from "../../lib/string";
import { Filter } from "../../types/BackendTypes";
import { filterDocIsEmpty } from "../../filter_builder/lib";

export default function AudienceTargeting({
  filter,
  audience,
  loading,
  objTargetsOrgs,
  entityName,
}: {
  filter: Filter;
  audience: any;
  loading: boolean;
  objTargetsOrgs: boolean;
  entityName: string;
}) {
  const audienceName = objTargetsOrgs ? "organization" : "person";
  const pathAudienceType = objTargetsOrgs ? "organizations" : "people";

  const encodedFilterString = btoa(JSON.stringify(filter));
  const viewAudiencePath = `/audience/${pathAudienceType}?f=${encodedFilterString}`;

  if (filterDocIsEmpty(filter)) {
    return (
      <Alert
        message="Select some filters to target an audience"
        icon={true}
        size="small"
        iconPlacement={"top"}
      />
    );
  }

  return (
    <>
      <Alert
        message={`This ${entityName} is targeting `}
        icon={true}
        size="small"
        iconPlacement={"top"}
      >
        {loading && "-"}
        {!loading && (
          <span className="font-semibold capitalize">
            <Link
              to={viewAudiencePath}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer underline"
            >
              {pluralize((audience && audience.total) || 0, audienceName)}
            </Link>
          </span>
        )}
      </Alert>
    </>
  );
}
