import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useLazyQuery, NetworkStatus } from "@apollo/client";
import {
  AttributeTarget,
  TargetMemberTypeChoices,
  Flags,
} from "../__generated__/graphql";
import { GET_ORGS } from "../graphql/queries";
import { pluralize } from "../lib/string";
import { isEmptyFilterDoc } from "../lib/filters";
import { filterDocIsComplete } from "../filter_builder/lib";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import useQueryAndFilter from "../hooks/useQueryAndFilter";
import EmptyState from "../patterns/EmptyState";
import Organizations from "../patterns/illustrations/Organizations";
import PageHeader from "../patterns/PageHeader";
import FullScreenError from "../patterns/Errors";
import SkeletonScreen from "../patterns/Skeleton";
import OrgsTable from "./table";
import NewBehavior from "../behaviors/NewBehavior";
import NewOrgModal from "./NewOrgModal";
import NewCohortModal from "../cohorts/NewCohort";
import Cohorts from "../cohorts";
import { cohortToFilter } from "../cohort/lib";
import AudienceAttributeSummaries from "../patterns/AudienceAttributeSummaries";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

const PAGE_SIZE = 50;

const SEARCH_DEBOUNCE_TIMEOUT = 1000;

export default function OrgsMain() {
  const [isPaginating, setIsPaginating] = useState(false);
  const [isNewOrgModalVisible, setIsNewOrgModalVisible] = useState(false);
  const [isNewCohortModalVisible, setIsNewCohortModalVisible] = useState(false);
  const [behaviorModalIsVisible, setBehaviorModalIsVisible] = useState(false);
  const [selectedCohort, setSelectedCohort] = useState<string | null>(null);
  const { query, updateQuery, filter, updateFilter } = useQueryAndFilter();
  const [
    getOrgs,
    { error, data, fetchMore, previousData, networkStatus, refetch },
  ] = useLazyQuery(GET_ORGS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const canUseCohorts = useIsFeatureEnabled(Flags.Cohorts);

  /* eslint-disable react-hooks/exhaustive-deps */
  // Initial fetch is not debounced
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      getOrgs({ variables: { filter: JSON.stringify(filter), query } });
    }
  }, []);

  const debouncedRefetch = useCallback(
    debounce(refetch, SEARCH_DEBOUNCE_TIMEOUT),
    [refetch],
  );
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      debouncedRefetch({ filter: JSON.stringify(filter), query });
    }
  }, [filter, query, debouncedRefetch]);

  const loadNext = useCallback(() => {
    setIsPaginating(true);
    try {
      fetchMore({
        variables: {
          cursor: data.allOrgs.edges[data.allOrgs.edges.length - 1].node.id,
          filter: JSON.stringify(filter),
          query: query,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [filter, query, data, fetchMore]);

  const selectCohort = useCallback(
    (cohortId: string) => {
      if (cohortId === selectedCohort) {
        setSelectedCohort(null);
        updateFilter({
          version: "1",
          filters: {
            operator: "AND",
            conditions: [],
          },
        });
      } else {
        setSelectedCohort(cohortId);
        updateFilter({
          version: "1",
          filters: {
            operator: "AND",
            conditions: [cohortToFilter(cohortId, AttributeTarget.Org)],
          },
        });
      }
    },
    [selectedCohort, setSelectedCohort, updateFilter],
  );

  const nextPageTrigger = useInfiniteScroll(loadNext);

  if (error) {
    return <FullScreenError />;
  }

  if (!previousData && !data) {
    return <></>;
  }

  const currentData = data || previousData;

  const loading = networkStatus === NetworkStatus.setVariables;

  const showEmptyState =
    currentData.allOrgs.total === 0 && isEmptyFilterDoc(filter);

  const hasMore = currentData.allOrgs.total > currentData.allOrgs.edges.length;
  return (
    <div>
      {isNewOrgModalVisible && (
        <NewOrgModal closeModal={() => setIsNewOrgModalVisible(false)} />
      )}
      {isNewCohortModalVisible && (
        <NewCohortModal
          targetMemberType={TargetMemberTypeChoices.Org}
          closeModal={() => setIsNewCohortModalVisible(false)}
        />
      )}
      {behaviorModalIsVisible && (
        <NewBehavior
          closeModal={() => setBehaviorModalIsVisible(false)}
          audienceCriteria={filter}
          predefinedTargetMemberType={TargetMemberTypeChoices.Org}
        />
      )}
      <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
        {showEmptyState ? (
          <EmptyState
            title="This is where you'll find your organizations"
            description="You’ll be able to filter and sort this list, or use it as the audience
        for future campaigns "
            icon={<Organizations />}
            buttonLabel="Add an organization"
            onClick={() => {
              setIsNewOrgModalVisible(true);
            }}
          />
        ) : (
          <div className="flex flex-col">
            {canUseCohorts && (
              <>
                <PageHeader
                  header="Organization cohorts"
                  subhead={""}
                  buttons={[
                    {
                      type: "primary" as const,
                      label: "Add a cohort",
                      action: () => {
                        setIsNewCohortModalVisible(true);
                      },
                    },
                  ]}
                />
                <Cohorts
                  selectedCohort={selectedCohort}
                  targetMemberType={TargetMemberTypeChoices.Org}
                  onSelectCohort={selectCohort}
                />
              </>
            )}
            <PageHeader
              header="Organizations"
              subhead={pluralize(currentData.allOrgs.total, "organization")}
              buttons={[
                {
                  type: "primary" as const,
                  label: "Add an organization",
                  action: () => {
                    setIsNewOrgModalVisible(true);
                  },
                },
              ]}
            />
            <div className="px-8">
              <AudienceAttributeSummaries
                filter={filter}
                attributeTarget={AttributeTarget.Org}
              />
            </div>
            <OrgsTable
              orgs={currentData.allOrgs.edges}
              query={query}
              filter={filter}
              updateQuery={updateQuery}
              updateFilter={updateFilter}
              showNewBehaviorModal={setBehaviorModalIsVisible}
              isLoading={loading}
            />
            {hasMore && <div ref={nextPageTrigger}></div>}
            {isPaginating ? (
              <SkeletonScreen />
            ) : (
              <div className="w-full min-h-96"></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
