import { BehaviorType, Timeseries } from "../../__generated__/graphql";
import Line from "../../patterns/charts/Line";

export default function CompletedLineChart({
  behavior,
  subBehaviors,
}: {
  behavior: BehaviorType;
  subBehaviors?: BehaviorType[];
}) {
  const hasSubBehaviors = subBehaviors && subBehaviors.length > 0;
  let lineStats: { id: string; data: Timeseries[] }[] = [];
  if (hasSubBehaviors) {
    lineStats = subBehaviors?.map((subBehavior) => {
      return {
        id: subBehavior.name,
        data: subBehavior.stats.completed.timeSeries,
      };
    });
  } else {
    lineStats = [
      {
        id: behavior.name,
        data: behavior.stats.completed.timeSeries,
      },
    ];
  }
  return (
    <div className="h-80">
      <Line
        data={lineStats}
        type={"time"}
        colors={["#1A41CF", "#667177", "#FBC833"]}
        curve="monotoneX"
        margin={{ top: 40, right: 40, bottom: 90, left: 40 }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: -80,
            translateY: 80,
            itemsSpacing: 200,
            itemDirection: "left-to-right",
            itemWidth: 0,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
