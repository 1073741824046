import { useState } from "react";
import { useQuery } from "@apollo/client";
import { LifecycleStages, ORDERED_LIFECYCLE_STAGES } from "../types/LocalTypes";
import { GET_LIFECYCLE_STAGES } from "../graphql/queries";
import PageHeader, { Button } from "../patterns/PageHeader";
import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import NewLifecycleStage from "./NewLifecycleStage";
import Overview from "./Overview";

export const VIEW_PARAM = "lifecycle_stage";

function lifecycleStageSort(a: any, b: any) {
  const ax = ORDERED_LIFECYCLE_STAGES.indexOf(a[1].name as LifecycleStages);
  const bx = ORDERED_LIFECYCLE_STAGES.indexOf(b[1].name as LifecycleStages);
  return ax - bx;
}

export default function LifecycleStagesMain() {
  const [modalIsVisible, setModalVisible] = useState(false);
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGES);
  const lifecycleStages = data?.allLifecycleStages;
  const addLifecycleStageButton = {
    type: "lifecycle_stage",
    label: "Add a Lifecycle Stage",
    action: () => setModalVisible(true),
  } as Button;

  if (loading) return <SkeletonScreen />;
  if (error) return <FullScreenError />;

  const sortedStages = Object.entries(lifecycleStages)
    .sort((a, b) => {
      return lifecycleStageSort(a, b);
    })
    .map((stage) => stage[1]);

  return (
    <>
      <PageHeader
        header="Lifecycle Stages"
        subhead="Track and optimize each step of your customer's journey"
        buttons={[addLifecycleStageButton]}
      />
      {modalIsVisible && (
        <NewLifecycleStage closeModal={() => setModalVisible(false)} />
      )}
      {sortedStages.length > 0 ? (
        <div className="flex flex-col gap-4">
          {sortedStages.map((stage) => (
            <Overview lifecycleStage={stage} />
          ))}
        </div>
      ) : (
        <EmptyState
          title="What are your customers doing?"
          icon={<AttributesEmpty />}
          description={
            "By setting up Lifecycle Stages, you can build insights over time around what actions your customers are taking"
          }
        />
      )}
    </>
  );
}
