import React from "react";
import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import SettingsBlock from "../SettingsBlock";
import BuilderSectionHeader from "./builder-section-header";
import SectionProps from "./section_props";

export default function ExitActionsSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const targetMemberType = data.campaignById.targetMemberType;
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const hasExitActions = campaignDefinition.exit_actions.actions.length > 0;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Fallback Actions"
        description={`Automate what’ll happen after a ${isOrgCampaign ? "member of the organization" : "customer"} exits this campaign without completing the event`}
        isValid={hasExitActions}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {!hasExitActions && <div></div>}
        {campaignDefinition.exit_actions.actions
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map((action) => {
            switch (action.type) {
              case "update_member":
                return (
                  <SettingsBlock
                    title={
                      isOrgCampaign ? "Update organization" : "Update Person"
                    }
                    section="attributes"
                    onClick={() => {}}
                  />
                );
              case "notify_exited":
                return (
                  <SettingsBlock
                    title="Notify the team"
                    section="notify"
                    onClick={() => {}}
                  />
                );
              case "update_crm":
                return (
                  <SettingsBlock
                    title="Update CRM fields"
                    section="update-crm"
                    onClick={() => {}}
                  />
                );
              case "incentive":
              case "delayed_email":
              case "email":
              case "notify_entered":
              case "notify_completed":
              case "webhook":
              case "ai_summarize_health":
              case "ai_summarize_health_to_slack":
                return <></>;
              default:
                const exhaustiveCheck: never = action;
                throw new Error(`Unknown action type ${exhaustiveCheck}`);
            }
          })}
      </div>
    </div>
  );
}
