import { uuidv7 } from "@kripod/uuidv7";
import { CompletionCriteria, Filter } from "../../types/BackendTypes";
import {
  CohortType,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";

export const NULL_COHORT = {
  id: "",
  name: "",
  status: "DRAFT",
  targetMemberType: TargetMemberTypeChoices.Org,
  audience: {},
  observing: {},
  aiSummarySettings: {
    period: null,
    action: null,
  },
};

interface WaitCriteria {
  version: "1";
  type: "wait";
  waitFor: number;
  timeUnit: string;
}

interface InitAction {
  type: "init";
  data: CohortType;
}

interface UpdateAudience {
  type: "UpdateAudience";
  data: Filter;
}

interface UpdateCompletionCriteria {
  type: "UpdateCompletionCriteria";
  criteria: CompletionCriteria;
}

interface EnableAiHealthSummary {
  type: "EnableAiHealthSummary";
}

interface EnableAiHealthSummaryToSlack {
  type: "EnableAiHealthSummaryToSlack";
}

interface UpdateAiHealthSummaryPeriod {
  type: "UpdateAiHealthSummaryPeriod";
  data: {
    period: WaitCriteria;
  };
}

interface UpdateAiHealthSummaryToSlackChannel {
  type: "UpdateAiHealthSummaryToSlackChannel";
  data: {
    channel: string;
  };
}

interface RemoveAiHealthSummary {
  type: "RemoveAiHealthSummary";
}

export type CohortUpdate =
  | UpdateAudience
  | UpdateCompletionCriteria
  | EnableAiHealthSummary
  | EnableAiHealthSummaryToSlack
  | UpdateAiHealthSummaryPeriod
  | UpdateAiHealthSummaryToSlackChannel
  | RemoveAiHealthSummary;

export function updateCohortReducer(
  prev: CohortType,
  reducerAction: InitAction | CohortUpdate,
): CohortType {
  switch (reducerAction.type) {
    case "init":
      return {
        ...NULL_COHORT,
        id: reducerAction.data.id,
        name: reducerAction.data.name,
        status: reducerAction.data.status,
        targetMemberType: reducerAction.data.targetMemberType,
        audience: JSON.parse(reducerAction.data.audience),
        observing: JSON.parse(reducerAction.data.observing),
        aiSummarySettings: {
          period: JSON.parse(reducerAction.data.aiSummarySettings.period),
          action: JSON.parse(reducerAction.data.aiSummarySettings.action),
        },
      };
    case "UpdateAudience":
      return {
        ...prev,
        audience: {
          criteria: [
            {
              type: "filter",
              version: "1",
              filter: reducerAction.data,
            },
          ],
        },
      };

    case "UpdateCompletionCriteria":
      return { ...prev, observing: { criteria: [reducerAction.criteria] } };
    case "EnableAiHealthSummary":
      return {
        ...prev,
        aiSummarySettings: {
          period: {
            version: "1",
            type: "wait",
            waitFor: prev.aiSummarySettings.period?.waitFor ?? 30,
            timeUnit: prev.aiSummarySettings.period?.timeUnit ?? "days",
          },
          action: {
            type: "ai_summarize_health",
            id: uuidv7(),
            enabled: true,
          },
        },
      };
    case "EnableAiHealthSummaryToSlack":
      return {
        ...prev,
        aiSummarySettings: {
          period: {
            version: "1",
            type: "wait",
            waitFor: prev.aiSummarySettings.period?.waitFor ?? 30,
            timeUnit: prev.aiSummarySettings.period?.timeUnit ?? "days",
          },
          action: {
            type: "ai_summarize_health_to_slack",
            id: uuidv7(),
            channel: "",
            delivery: {
              mode: "single",
            },
            enabled: true,
          },
        },
      };
    case "UpdateAiHealthSummaryPeriod":
      return {
        ...prev,
        aiSummarySettings: {
          ...prev.aiSummarySettings,
          period: reducerAction.data.period,
        },
      };
    case "UpdateAiHealthSummaryToSlackChannel":
      return {
        ...prev,
        aiSummarySettings: {
          ...prev.aiSummarySettings,
          action: {
            ...prev.aiSummarySettings.action,
            channel: reducerAction.data.channel,
          },
        },
      };
    case "RemoveAiHealthSummary":
      return {
        ...prev,
        aiSummarySettings: {
          period: null,
          action: null,
        },
      };

    default:
      const exhaustiveCheck: never = reducerAction;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}
