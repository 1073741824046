import { graphql } from "../__generated__";

export const CREATE_CAMPAIGN = graphql(`
  mutation CreateCampaign(
    $name: String!
    $targetMemberType: TargetMemberTypeChoices!
    $fromBehaviorId: String
    $generateMessageContentViaLlm: Boolean
  ) {
    createCampaign(
      name: $name
      targetMemberType: $targetMemberType
      fromBehaviorId: $fromBehaviorId
      generateMessageContentViaLlm: $generateMessageContentViaLlm
    ) {
      ok
      campaign {
        id
      }
    }
  }
`);

export const CREATE_BEHAVIOR = graphql(`
  mutation CreateBehavior(
    $name: String!
    $targetMemberType: TargetMemberTypeChoices!
    $status: String
    $audience: JSONString
    $observing: JSONString
    $actionsOnEnter: JSONString
    $actionsOnCompletion: JSONString
    $actionsWhileObserving: JSONString
    $lifecycleStageId: String
  ) {
    createBehavior(
      name: $name
      targetMemberType: $targetMemberType
      status: $status
      audience: $audience
      observing: $observing
      actionsOnEnter: $actionsOnEnter
      actionsOnCompletion: $actionsOnCompletion
      actionsWhileObserving: $actionsWhileObserving
      lifecycleStageId: $lifecycleStageId
    ) {
      ok
      behavior {
        id
      }
    }
  }
`);

export const CREATE_REFERRAL = graphql(`
  mutation CreateReferral($name: String!) {
    createReferral(name: $name) {
      ok
      referral {
        id
      }
    }
  }
`);

export const CREATE_COHORT = graphql(`
  mutation CreateCohort(
    $name: String!
    $targetMemberType: TargetMemberTypeChoices!
    $audience: JSONString
    $observing: JSONString
  ) {
    createCohort(
      name: $name
      targetMemberType: $targetMemberType
      audience: $audience
      observing: $observing
    ) {
      ok
      cohort {
        id
      }
    }
  }
`);

export const UPDATE_CAMPAIGN_DEFINITION = graphql(`
  mutation UpdateCampaignDefinition($id: String!, $definition: JSONString!) {
    updateCampaignDefinition(id: $id, definition: $definition) {
      ok
      campaign {
        id
        definition
      }
    }
  }
`);

export const UPDATE_BEHAVIOR = graphql(`
  mutation UpdateBehavior(
    $id: String!
    $name: String!
    $status: String!
    $audience: JSONString!
    $observing: JSONString!
    $actionsOnEnter: JSONString!
    $actionsOnCompletion: JSONString!
    $actionsWhileObserving: JSONString!
  ) {
    updateBehavior(
      id: $id
      name: $name
      status: $status
      audience: $audience
      observing: $observing
      actionsOnEnter: $actionsOnEnter
      actionsOnCompletion: $actionsOnCompletion
      actionsWhileObserving: $actionsWhileObserving
    ) {
      ok
      behavior {
        id
        name
        status
        targetMemberType
        audience
        observing
        actionsOnEnter
        actionsOnCompletion
        actionsWhileObserving
      }
    }
  }
`);

export const UPDATE_COHORT = graphql(`
  mutation UpdateCohort(
    $id: String!
    $name: String!
    $status: String!
    $audience: JSONString!
    $observing: JSONString!
    $aiSummarySettings: JSONString!
  ) {
    updateCohort(
      id: $id
      name: $name
      status: $status
      audience: $audience
      observing: $observing
      aiSummarySettings: $aiSummarySettings
    ) {
      ok
      cohort {
        id
        name
        status
        targetMemberType
        audience
        observing
        aiSummarySettings {
          period
          action
        }
      }
    }
  }
`);

export const DELETE_BEHAVIOR = graphql(`
  mutation DeleteBehavior($id: String!) {
    deleteBehavior(id: $id) {
      ok
    }
  }
`);

export const DELETE_COHORT = graphql(`
  mutation DeleteCohort($id: String!) {
    deleteCohort(id: $id) {
      ok
    }
  }
`);

export const SET_CAMPAIGN_LIVE = graphql(`
  mutation SetCampaignLive($id: String!) {
    setCampaignLive(id: $id) {
      ok
      campaign {
        id
        status
        validationErrors
      }
    }
  }
`);

export const PAUSE_CAMPAIGN = graphql(`
  mutation PauseCampaignLive($id: String!) {
    pauseCampaign(id: $id) {
      ok
      campaign {
        id
        status
      }
    }
  }
`);

export const DELETE_CAMPAIGN = graphql(`
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id) {
      ok
    }
  }
`);

export const COMPLETE_CAMPAIGN = graphql(`
  mutation CompleteCampaign($id: String!) {
    completeCampaign(id: $id) {
      ok
      campaign {
        id
        status
      }
    }
  }
`);

export const ARCHIVE_CAMPAIGN = graphql(`
  mutation ArchiveCampaign($id: String!) {
    archiveCampaign(id: $id) {
      ok
    }
  }
`);

export const UPDATE_REFERRAL = graphql(`
  mutation UpdateReferral(
    $id: String!
    $name: String!
    $status: String!
    $actionsOnReferred: JSONString!
  ) {
    updateReferral(
      id: $id
      name: $name
      status: $status
      actionsOnReferred: $actionsOnReferred
    ) {
      ok
      referral {
        id
        name
        status
        actionsOnReferred
      }
    }
  }
`);

export const CREATE_ATTRIBUTE = graphql(`
  mutation CreateAttribute(
    $name: String!
    $source: String!
    $type: AttrTypeEnum
    $target: AttributeTarget
  ) {
    createAttribute(
      target: $target
      name: $name
      source: $source
      aType: $type
    ) {
      ok
      attribute {
        name
        aType
      }
    }
  }
`);

export const UPDATE_PERSON_ATTRIBUTE = graphql(`
  mutation UpdatePersonAttribute(
    $entityId: String!
    $name: String!
    $source: String!
    $value: JSONString!
  ) {
    updatePersonAttribute(
      personId: $entityId
      name: $name
      source: $source
      value: $value
    ) {
      ok
      attribute {
        id
        source
        aType
        value
      }
    }
  }
`);

export const UPDATE_ORG_ATTRIBUTE = graphql(`
  mutation UpdateOrgAttribute(
    $entityId: String!
    $name: String!
    $source: String!
    $value: JSONString!
  ) {
    updateOrgAttribute(
      orgId: $entityId
      name: $name
      source: $source
      value: $value
    ) {
      ok
      attribute {
        id
        source
        aType
        value
      }
    }
  }
`);

export const CREATE_CRM_INTEGRATION = graphql(`
  mutation CreateCrmIntegration($type: CrmTypeChoices!, $config: JSONString!) {
    createCrmIntegration(type: $type, config: $config) {
      ok
      redirect
    }
  }
`);

export const CREATE_MESSAGING_INTEGRATION = graphql(`
  mutation CreateMessagingIntegration(
    $type: MessagingTypeChoices!
    $config: JSONString!
  ) {
    createMessagingIntegration(type: $type, config: $config) {
      ok
      redirect
    }
  }
`);

export const DELETE_MESSAGING_INTEGRATION = graphql(`
  mutation DeleteMessagingIntegration($messagingIntegrationId: ID!) {
    deleteMessagingIntegration(
      messagingIntegrationId: $messagingIntegrationId
    ) {
      ok
    }
  }
`);

export const UPDATE_MESSAGING_INTEGRATION = graphql(`
  mutation UpdateMessagingIntegration($id: ID!, $config: JSONString!) {
    updateMessagingIntegration(id: $id, config: $config) {
      ok
      messagingIntegration {
        id
        config
      }
    }
  }
`);

export const CREATE_EVENTS_INTEGRATION = graphql(`
  mutation CreateEventsIntegration(
    $type: EventsTypeChoices!
    $props: JSONString!
    $auth: JSONString!
  ) {
    createEventsIntegration(type: $type, props: $props, auth: $auth) {
      ok
      redirect
    }
  }
`);

export const DELETE_EVENTS_INTEGRATION = graphql(`
  mutation DeleteEventsIntegration($eventsIntegrationId: ID!) {
    deleteEventsIntegration(eventsIntegrationId: $eventsIntegrationId) {
      ok
    }
  }
`);

export const UPDATE_EVENTS_INTEGRATION = graphql(`
  mutation UpdateEventsIntegration(
    $id: ID!
    $props: JSONString!
    $auth: JSONString!
  ) {
    updateEventsIntegration(id: $id, props: $props, auth: $auth) {
      ok
      eventsIntegration {
        id
        eventsType
        config
        auth
      }
    }
  }
`);

export const CREATE_LOOPS_INTEGRATION = graphql(`
  mutation CreateLoopsIntegration(
    $type: LoopsTypeChoices!
    $config: JSONString!
  ) {
    createLoopsIntegration(type: $type, config: $config) {
      ok
      redirect
    }
  }
`);

export const DELETE_LOOPS_INTEGRATION = graphql(`
  mutation DeleteLoopsIntegration($loopsIntegrationId: ID!) {
    deleteLoopsIntegration(loopsIntegrationId: $loopsIntegrationId) {
      ok
    }
  }
`);

export const UPDATE_LOOPS_INTEGRATION = graphql(`
  mutation UpdateLoopsIntegration($id: ID!, $props: JSONString!) {
    updateLoopsIntegration(id: $id, props: $props) {
      ok
      loopsIntegration {
        id
        config
      }
    }
  }
`);

export const CREATE_MESSAGING_SENDER = graphql(`
  mutation CreateMessagingSender(
    $messagingIntegrationId: ID!
    $displayName: String!
    $externalId: String!
    $replyTo: String!
  ) {
    createMessagingSender(
      messagingIntegrationId: $messagingIntegrationId
      displayName: $displayName
      externalId: $externalId
      replyTo: $replyTo
    ) {
      ok
      messagingSender {
        id
        displayName
        externalId
        replyTo
      }
    }
  }
`);

export const DELETE_MESSAGING_SENDER = graphql(`
  mutation DeleteMessagingSender($messagingSenderId: ID!) {
    deleteMessagingSender(messagingSenderId: $messagingSenderId) {
      ok
    }
  }
`);

export const CREATE_PERSON = graphql(`
  mutation CreatePerson($name: String!, $email: String!) {
    createPerson(name: $name, email: $email) {
      ok
      person {
        name
        email
      }
    }
  }
`);

export const CREATE_ORG = graphql(`
  mutation CreateOrg($name: String!) {
    createOrg(name: $name) {
      ok
      org {
        name
      }
    }
  }
`);

export const CREATE_INCENTIVE = graphql(`
  mutation CreateIncentive(
    $type: IncentiveTypes!
    $name: String!
    $config: JSONString!
    $cost: Int
  ) {
    createIncentive(type: $type, name: $name, config: $config, cost: $cost) {
      ok
      incentive {
        type
        name
        config
        cost
      }
    }
  }
`);

export const UPDATE_PASSWORD = graphql(`
  mutation PasswordUpdate($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
      ok
    }
  }
`);

export const UPDATE_PERSON_FROM_CRM = graphql(`
  mutation UpdatePersonFromCrm($personId: String!) {
    updatePersonFromCrm(personId: $personId) {
      ok
      person {
        ...personFragment
      }
    }
  }
`);

export const UPDATE_USER_NAME = graphql(`
  mutation UpdateUserName($newName: String!) {
    updateUserName(newName: $newName) {
      ok
      userProfile {
        id
        name
        apiToken
      }
    }
  }
`);
export const CREATE_INVITE = graphql(`
  mutation CreateInvite($email: String!) {
    createInvite(email: $email) {
      ok
    }
  }
`);

export const DELETE_INVITE = graphql(`
  mutation DeleteInvite($id: ID!) {
    deleteInvite(id: $id) {
      ok
    }
  }
`);

export const CREATE_LIFECYCLE_STAGE = graphql(`
  mutation CreateLifecycleStage(
    $name: String!
    $targetMemberType: TargetMemberTypeChoices!
  ) {
    createLifecycleStage(name: $name, targetMemberType: $targetMemberType) {
      ok
      lifecycleStage {
        ...lifecycleStageFragment
      }
    }
  }
`);

export const UPDATE_LIFECYCLE_STAGE = graphql(`
  mutation UpdateLifecycleStage($id: String!, $name: String) {
    updateLifecycleStage(id: $id, name: $name) {
      ok
      lifecycleStage {
        ...lifecycleStageFragment
      }
    }
  }
`);

export const CREATE_LIFECYCLE_STAGE_OBJECTIVE = graphql(`
  mutation CreateLifecycleStageObjective(
    $lifecycleStageId: String!
    $name: String!
    $targetMemberType: TargetMemberTypeChoices!
    $status: String
    $audience: JSONString
    $observing: JSONString
    $actionsOnEnter: JSONString
    $actionsOnCompletion: JSONString
    $actionsWhileObserving: JSONString
  ) {
    createLifecycleStageObjective(
      lifecycleStageId: $lifecycleStageId
      name: $name
      targetMemberType: $targetMemberType
      status: $status
      audience: $audience
      observing: $observing
      actionsOnEnter: $actionsOnEnter
      actionsOnCompletion: $actionsOnCompletion
      actionsWhileObserving: $actionsWhileObserving
    ) {
      ok
      objective {
        id
        name
      }
    }
  }
`);

export const SEND_TEST_EMAIL = graphql(`
  mutation SendTestEmail(
    $senderId: ID!
    $recipient: String!
    $subject: String!
    $body: String!
  ) {
    sendTestEmail(
      senderId: $senderId
      recipient: $recipient
      subject: $subject
      body: $body
    ) {
      ok
    }
  }
`);

export const ENABLE_FEATURE_FLAG = graphql(`
  mutation EnableFeatureFlag($flag: Flags!) {
    enableFeatureFlag(flag: $flag) {
      ok
    }
  }
`);

export const DISABLE_FEATURE_FLAG = graphql(`
  mutation DisableFeatureFlag($flag: Flags!) {
    disableFeatureFlag(flag: $flag) {
      ok
    }
  }
`);

export const CREATE_SEGMENT_RESOURCES_FOR_INTEGRATION = graphql(`
  mutation CreateSegmentResourcesForIntegration($sourceId: String!) {
    createSegmentResourcesForIntegration(sourceId: $sourceId) {
      ok
    }
  }
`);

export const ENABLE_EVENT_INTEGRATION_TASK = graphql(`
  mutation EnableEventIntegrationTask(
    $integrationId: String!
    $taskType: EventIntegrationTaskType
  ) {
    enableEventIntegrationTask(
      integrationId: $integrationId
      taskType: $taskType
    ) {
      ok
      task {
        ...periodicTask
      }
    }
  }
`);

export const DISABLE_EVENT_INTEGRATION_TASK = graphql(`
  mutation DisableEventsIntegrationTask(
    $integrationId: String!
    $taskType: EventIntegrationTaskType
  ) {
    disableEventIntegrationTask(
      integrationId: $integrationId
      taskType: $taskType
    ) {
      ok
      task {
        ...periodicTask
      }
    }
  }
`);

export const CREATE_SCHEDULED_SUMMARY = graphql(`
  mutation CreateScheduledSummary(
    $objectId: String!
    $config: ConfigInput!
    $schedule: ScheduleInput!
  ) {
    createScheduledSummary(
      objectId: $objectId
      config: $config
      schedule: $schedule
    ) {
      ok
      scheduledSummary {
        id
        config {
          slack {
            channelId
          }
          statesToSummarize {
            stateName
            countPrefix
            listPrefix
            listItemTemplate
            includeCount
            includeList
          }
        }
        schedule {
          hour
          minute
          dayOfWeek
        }
      }
    }
  }
`);

export const UPDATE_SCHEDULED_SUMMARY = graphql(`
  mutation UpdateScheduledSummary(
    $objectId: String!
    $config: ConfigInput!
    $schedule: ScheduleInput!
  ) {
    updateScheduledSummary(
      objectId: $objectId
      config: $config
      schedule: $schedule
    ) {
      ok
      scheduledSummary {
        id
        config {
          slack {
            channelId
          }
          statesToSummarize {
            stateName
            countPrefix
            listPrefix
            listItemTemplate
            includeCount
            includeList
          }
        }
        schedule {
          hour
          minute
          dayOfWeek
        }
      }
    }
  }
`);

export const DELETE_SCHEDULED_SUMMARY = graphql(`
  mutation DeleteScheduledSummary($objectId: String!) {
    deleteScheduledSummary(objectId: $objectId) {
      ok
    }
  }
`);

export const CREATE_REFERRER_REFEREE_PAIR = graphql(`
  mutation CreateReferrerRefereePair(
    $referrerName: String!
    $referrerEmail: String!
    $refereeName: String!
    $refereeEmail: String!
  ) {
    createReferrerRefereePair(
      referrerName: $referrerName
      referrerEmail: $referrerEmail
      refereeName: $refereeName
      refereeEmail: $refereeEmail
    ) {
      person {
        ...personFragment
      }
      ok
    }
  }
`);

export const CREATE_EVENT_FOR_PERSON = graphql(`
  mutation CreateEventForPerson($eventName: String!, $personId: String!) {
    createEventForPerson(eventName: $eventName, personId: $personId) {
      ok
    }
  }
`);

export const BULK_SET_ATTRIBUTES_AND_EVENTS = graphql(`
  mutation BulkSetAttributesAndEvents(
    $attrUpdates: [AttributeUpdateInputType]
    $events: [String]
    $filterGroup: JSONString
    $numberToSet: Int
    $proportionToSet: Float
  ) {
    bulkSetAttributesAndEvents(
      attrUpdates: $attrUpdates
      events: $events
      filterGroup: $filterGroup
      numberToSet: $numberToSet
      proportionToSet: $proportionToSet
    ) {
      ok
    }
  }
`);

export const SEED_CAMPAIGN_OR_BEHAVIOR = graphql(`
  mutation SeedCampaignOrBehavior(
    $objectId: String!
    $objectType: String!
    $enterOverDays: Int!
    $completionRate: Float!
    $avgDaysToComplete: Float!
  ) {
    seedCampaignOrBehavior(
      objectId: $objectId
      objectType: $objectType
      enterOverDays: $enterOverDays
      completionRate: $completionRate
      avgDaysToComplete: $avgDaysToComplete
    ) {
      ok
    }
  }
`);

export const GENERATE_PLAN_FOR_OBJECTIVE_COHORT = graphql(`
  mutation GeneratePlanForObjectiveCohort(
    $objectiveId: String!
    $cohortId: String!
  ) {
    generatePlanForObjectiveCohort(
      objectiveId: $objectiveId
      cohortId: $cohortId
    ) {
      ok
      campaign {
        id
        name
      }
      cohort {
        id
        name
      }
    }
  }
`);
