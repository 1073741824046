import { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GET_MEMBER_TIMELINE } from "../graphql/queries";
import DOMPurify from "dompurify";
import SectionHeader from "../patterns/SectionHeader";
import { formatLong } from "../lib/date";
import {
  AttributeTarget,
  MemberTimelineEmailsSentType,
  MemberTimelineEventType,
  MemberTimelineSummaryContentType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import {
  UserPlusIcon,
  UserMinusIcon,
  ClockIcon,
  TrophyIcon,
  XCircleIcon,
  GiftIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { cohortToFilter } from "../cohort/lib";
import { cx } from "../lib/cx";
import { pluralize } from "../lib/string";

const sanitizeConfig = {
  ALLOWED_TAGS: ["h1", "h2", "h3", "p", "ul", "ol", "li", "hr"],
  ALLOWED_ATTR: [], // empty array means no attributes allowed
};

interface ItemDescriptionProps {
  objectId: string;
  eventType: string;
  name: string;
}

const CohortTimelineItemDescription = ({
  objectId,
  eventType,
  name,
}: ItemDescriptionProps) => {
  const verb = eventType === "completed" ? "left" : "entered";
  const IconComponent =
    eventType === "completed" ? UserMinusIcon : UserPlusIcon;
  const iconColor =
    eventType === "completed" ? "text-red-500" : "text-blue-500";

  const filter = {
    version: "1",
    filters: {
      operator: "AND",
      conditions: [cohortToFilter(objectId, AttributeTarget.Org)],
    },
  };
  const encodedFilterString = btoa(JSON.stringify(filter));
  const viewAudiencePath = `/audience/organizations?f=${encodedFilterString}`;

  return (
    <>
      <IconComponent className={`h-4 w-4 inline-block mr-2 ${iconColor}`} />
      <span className="text-xs text-body-text-lighter leading-tight">
        {verb} the cohort{" "}
        <Link className="cursor-pointer underline" to={viewAudiencePath}>
          {name}
        </Link>{" "}
        {" on "}
      </span>
    </>
  );
};

const ObjectiveTimelineItemDescription = ({
  eventType,
  name,
  objectId,
}: ItemDescriptionProps) => {
  const verb = eventType === "completed" ? "Completed" : "Entered";
  const IconComponent = eventType === "completed" ? TrophyIcon : ClockIcon;
  const iconColor =
    eventType === "completed" ? "text-green-500" : "text-active-blue";

  const objectivePath = `/behaviors/${objectId}`;
  return (
    <>
      <IconComponent className={`h-4 w-4 inline-block mr-2 ${iconColor}`} />
      <span className="text-xs text-gray-600 leading-tight">
        {verb} the objective{" "}
        <Link className="cursor-pointer underline" to={objectivePath}>
          {name}
        </Link>{" "}
        {" on "}
      </span>
    </>
  );
};

const CampaignTimelineItemDescription = ({
  eventType,
  name,
  objectId,
}: ItemDescriptionProps) => {
  let verb: string;
  let iconColor: string;
  let IconComponent;
  switch (eventType) {
    case "completed":
      verb = "completed";
      iconColor = "text-green-500";
      IconComponent = GiftIcon;
      break;
    case "exited":
      verb = "exited without completing";
      iconColor = "text-red-500";
      IconComponent = XCircleIcon;
      break;
    case "entered":
      verb = "entered";
      iconColor = "text-active-blue ";
      IconComponent = ClockIcon;
      break;
    default:
      verb = "entered";
      iconColor = "text-active-blue";
      IconComponent = ClockIcon;
  }
  const objectivePath = `/campaigns/${objectId}`;
  return (
    <>
      <IconComponent className={`h-4 w-4 inline-block mr-2 ${iconColor}`} />
      <span className="text-xs text-body-text leading-tight">
        {verb} the campaign{" "}
        <Link className="cursor-pointer underline" to={objectivePath}>
          {name}
        </Link>{" "}
        {" on "}
      </span>
    </>
  );
};

interface TimelineEventItemProps {
  item: MemberTimelineEventType;
}

export function TimelineEventItem({
  item: { name, objectId, timestamp, eventType, objectType },
}: TimelineEventItemProps) {
  const Components = {
    cohort: CohortTimelineItemDescription,
    objective: ObjectiveTimelineItemDescription,
    campaign: CampaignTimelineItemDescription,
  };
  if (!Components[objectType]) {
    return null;
  }
  const Component = Components[objectType];
  return (
    <div className="relative">
      {/* Dot along the timeline */}
      <div className="absolute top-3 w-3 h-3 bg-white border-2 border-active-blue rounded-full -ml-[7px]"></div>
      <div className="bg-grey-50 rounded-md border-1 border-rule-color ml-4 mb-4 p-2">
        <Component objectId={objectId} eventType={eventType} name={name} />
        <span className="text-xs text-body-text-lightest mt-1">
          {formatLong(timestamp)}
        </span>
      </div>
    </div>
  );
}

interface TimelineSummaryItemProps {
  item: MemberTimelineSummaryContentType;
}

export function TimelineSummaryItem({
  item: { objectId, timestamp, objectType, body },
}: TimelineSummaryItemProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="relative" onClick={() => setExpanded(!expanded)}>
      {/* Dot along the timeline */}
      <div className="absolute top-3 w-3 h-3 bg-white border-2 border-active-blue rounded-full -ml-[7px]"></div>
      <div className="bg-gray-50 p-2 rounded-md border-1 hover:shadow-lg cursor-pointer shadow-md ml-4 mb-4">
        <>
          <div>
            <DocumentTextIcon
              className={`h-4 w-4 inline-block mr-2 text-active-blue`}
            />
            <span className={cx(!expanded && "underline")}>
              summary generated
            </span>
            {" on "}
            <span className="text-xs text-gray-400 mt-1">
              {formatLong(timestamp)}
            </span>
          </div>
          <div
            className={cx(
              "transition-all duration-500 ease-in-out overflow-hidden",
              expanded ? "max-h-screen" : "max-h-0",
            )}
          >
            <div className="p-4">
              <div
                className="text-xs text-body-text ai_health_summary"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body, sanitizeConfig),
                }}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

interface TimelineEmailItemProps {
  item: MemberTimelineEmailsSentType;
}

export function TimelineEmailItem({
  item: { objectId, timestamp, objectType, sendingObject, messagesSent },
}: TimelineEmailItemProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="relative" onClick={() => setExpanded(!expanded)}>
      {/* Dot along the timeline */}
      <div
        className="absolute top-3 w-3 h-3 bg-white border-2 border-active-blue rounded-full"
        style={{ marginLeft: "-7px" }}
      ></div>
      <div className="bg-gray-50 p-2 rounded-md border-1 hover:shadow-lg cursor-pointer shadow-md ml-4 mb-4">
        <>
          <div>
            <PaperAirplaneIcon
              className={`h-4 w-4 inline-block mr-2 text-green-500`}
            />
            <span className={cx(!expanded && "underline")}>
              {pluralize(messagesSent.length, "email")} sent from{" "}
              {sendingObject.name}
            </span>
            {" on "}
            <span className="text-xs text-gray-400 mt-1">
              {formatLong(timestamp)}
            </span>
          </div>
          <div
            className={cx(
              "transition-all duration-500 ease-in-out overflow-hidden",
              expanded ? "max-h-screen" : "max-h-0",
            )}
          >
            <div className="p-4 flex flex-col gap-3">
              <div className="text-xs leading-tight">
                Subject:{"  "}
                <span className="text-gray-400">{messagesSent[0].subject}</span>
              </div>
              <div className="pl-2">
                {messagesSent.map((message) => (
                  <div className="text-xs text-gray-400 leading-tight">
                    {message.recipient}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

interface MemberTimelineProps {
  memberId: string;
  targetMemberType: TargetMemberTypeChoices;
}

export default function MemberTimeline({
  memberId,
  targetMemberType,
}: MemberTimelineProps) {
  const { data: timelineData, loading } = useQuery(GET_MEMBER_TIMELINE, {
    variables: { memberId, targetMemberType },
  });

  const hasTimelineEvents = useMemo(() => {
    return (
      timelineData &&
      timelineData.memberTimelineByMemberId.timelineItems.length > 0
    );
  }, [timelineData]);

  if (!timelineData || loading || !hasTimelineEvents) {
    return null;
  }

  return (
    <div className="">
      <SectionHeader title="Activity" icon="Activity"></SectionHeader>
      <div className="flex-row relative border-l-2 border-active-blue w-auto ml-2">
        {timelineData.memberTimelineByMemberId.timelineItems.map((item) => {
          return (
            <>
              {item.__typename === "MemberTimelineEventType" && (
                <TimelineEventItem
                  key={`${item.objectId}-${item.eventType}-@-${item.timestamp}`}
                  item={item}
                />
              )}
              {item.__typename === "MemberTimelineSummaryContentType" && (
                <TimelineSummaryItem
                  key={`${item.objectId}-${item.objectType}-@-${item.timestamp}`}
                  item={item}
                />
              )}
              {item.__typename === "MemberTimelineEmailsSentType" && (
                <TimelineEmailItem
                  key={`${item.objectId}-${item.objectType}-@-${item.timestamp}`}
                  item={item}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
