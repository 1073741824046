import { useNavigate } from "react-router-dom";
import { MachineCampaignType } from "../__generated__/graphql";
import CampaignsIcon from "../patterns/symbols/CampaignsIcon";

export default function CampaignEntry({
  campaign,
  close,
}: {
  campaign: MachineCampaignType;
  close: () => void;
}) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white rounded-lg p-2.5 text-xs flex flex-row items-center gap-2 cursor-pointer"
      onClick={() => {
        navigate(`/campaigns/${campaign.id}`);
        close();
      }}
    >
      <div className="w-4 h-4">
        <CampaignsIcon strokeColor={"white"} />
      </div>
      <div className="flex flex-row items-center gap-2">{campaign.name}</div>
    </div>
  );
}
