import { COMPLETION_RATE_THRESHOLD } from "../ai_agent/AiAgentDrawer";

export default function EngagementCircle({
  completionPercentage,
  showText = false,
  pill = false,
}: {
  completionPercentage: number;
  showText?: boolean;
  pill?: boolean;
}) {
  // I've added an optional stroke color variant based on a `pill` props. This is in addition to the `completionPercentage`
  const strokeColor = pill
    ? completionPercentage >= COMPLETION_RATE_THRESHOLD
      ? "stroke-body-text-light"
      : "stroke-white"
    : completionPercentage >= COMPLETION_RATE_THRESHOLD
      ? "stroke-green-400"
      : "stroke-red";

  return (
    <svg viewBox="0 -1 38 38" className={strokeColor}>
      {/* Background circle */}
      <path
        className="fill-none stroke-[1] stroke-white"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {/* Completion progress circle */}
      <path
        className="fill-none stroke-[4] engagement-circle-icon"
        stroke-dasharray={`${completionPercentage}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {/* Optional text */}
      {showText && (
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          className="text-xxs stroke-none fill-body-text"
        >
          {completionPercentage}%
        </text>
      )}
    </svg>
  );
}
