import { ReferralType } from "../../../__generated__/graphql";
import { ActionList } from "../../../types/BackendTypes";
import SettingsBlock from "../../../campaign_form/SettingsBlock";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";

interface Props {
  referral: ReferralType;
  actionList: ActionList;
  openDrawer: () => void;
}

export default function FollowUpActionsBuilderSection({
  referral,
  actionList,
  openDrawer,
}: Props) {
  const hasFollowup = actionList?.actions?.length > 0;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Follow-up Actions"
        description={`Automate what’ll happen when someone is referred`}
        isValid={hasFollowup}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {!hasFollowup && <div></div>}
        {actionList?.actions
          ?.sort((a, b) => (a.id < b.id ? -1 : 1))
          ?.map((action) => {
            switch (action.type) {
              case "notify_completed":
                return (
                  <SettingsBlock
                    title="Notify the team"
                    section="notify"
                    onClick={() => {}}
                  />
                );
              case "email":
                return (
                  <SettingsBlock
                    title="Message the referrer"
                    section="email"
                    onClick={() => {}}
                  />
                );
              case "webhook":
                return (
                  <SettingsBlock
                    title="Send a webhook"
                    section="webhook"
                    onClick={() => {}}
                  />
                );
              case "update_crm":
                return (
                  <SettingsBlock
                    title="Update CRM fields"
                    section="update-crm"
                    onClick={() => {}}
                  />
                );
              case "update_member":
              case "incentive":
              case "delayed_email":
              case "notify_entered":
              case "notify_exited":
              case "ai_summarize_health":
              case "ai_summarize_health_to_slack":
                return <></>;
              default:
                const exhaustiveCheck: never = action;
                throw new Error(`Unknown action type ${exhaustiveCheck}`);
            }
          })}
      </div>
    </div>
  );
}
