import React from "react";
import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../../../__generated__/graphql";
import { ActionList } from "../../../types/BackendTypes";
import SettingsBlock from "../../../campaign_form/SettingsBlock";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";
import { ContextType, contextMap } from "../../lib";

interface Props {
  behavior: BehaviorType;
  actionList: ActionList;
  openDrawer: () => void;
  context?: ContextType;
}

export default function EntryActionsBuilderSection({
  behavior,
  actionList,
  openDrawer,
  context = "behavior",
}: Props) {
  const targetingOrgs =
    behavior.targetMemberType === TargetMemberTypeChoices.Org;
  const hasEntryActions = actionList.actions.length > 0;
  const contextName = contextMap[context as ContextType] ?? "Behavior";

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Entry Actions"
        description={`Automate what’ll happen when a ${targetingOrgs ? "member of the organization" : "customer"} enters the ${contextName}`}
        isValid={hasEntryActions}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {actionList.actions
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map((action) => {
            switch (action.type) {
              case "notify_entered":
                return (
                  <SettingsBlock
                    title="Notify the team"
                    section="notify"
                    onClick={() => {}}
                  />
                );

              case "webhook":
                return (
                  <SettingsBlock
                    title="Send a webhook"
                    section="webhook"
                    onClick={() => {}}
                  />
                );
              case "update_member":
                return (
                  <SettingsBlock
                    title={
                      targetingOrgs ? "Update organization" : "Update Person"
                    }
                    section="attributes"
                    onClick={() => {}}
                  />
                );
              case "update_crm":
                return (
                  <SettingsBlock
                    title="Update CRM fields"
                    section="update-crm"
                    onClick={() => {}}
                  />
                );
              case "email":
              case "incentive":
              case "delayed_email":
              case "notify_completed":
              case "notify_exited":
              case "ai_summarize_health":
              case "ai_summarize_health_to_slack":
                return <></>;
              default:
                const exhaustiveCheck: never = action;
                throw new Error(`Unknown action type ${exhaustiveCheck}`);
            }
          })}
      </div>
    </div>
  );
}
