import { useNavigate } from "react-router-dom";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import {
  BehaviorType,
  ObjectiveCohortRiskType,
} from "../__generated__/graphql";
import { COMPLETION_RATE_THRESHOLD } from "./AiAgentDrawer";
import LifecycleStageStatePill from "../patterns/LifecycleStageStatePill";
import SectionHeader from "../patterns/SectionHeader";
import CohortRisk from "./CohortRiskCard";

export default function ObjectiveCard({
  objective,
  risks,
  close,
  index,
  className,
}: {
  objective: BehaviorType;
  risks: ObjectiveCohortRiskType[];
  close: () => void;
  index: number;
  className?: string;
}) {
  const navigate = useNavigate();
  const entered = objective.stats.entered.count;
  const completed = objective.stats.completed.count;
  const objectiveCompletionRate =
    entered && Math.round((completed / entered) * 100);
  const avgTimeToCompletion =
    objective.stats.timeToCompletion.average.toFixed(2);

  const hasRisks = risks.length > 0;
  const belowEngagementThreshold =
    objectiveCompletionRate < COMPLETION_RATE_THRESHOLD;

  return (
    <>
      <div
        className={`p-4 bg-gradient-to-bl from-white/30 to-white/60 border border-white rounded-lg hover:shadow-md animate ${className}`}
      >
        <div className="flex flex-row">
          <div className="flex-grow">
            <div
              className="font-semibold flex flex-row items-center gap-2 cursor-pointer animate text-sm"
              onClick={() => {
                navigate(`/behaviors/${objective.id}?view=overview`);
                close();
              }}
            >
              <div className="flex rounded-full w-6 h-6 border border-body-text text-[10px] items-center justify-center text-medium">
                {index + 1}{" "}
              </div>
              <div className="flex flex-row flex-grow gap-1 items-center">
                <span className="-mb-4">
                  <SectionHeader title={objective.name} />
                </span>
                <ArrowTopRightOnSquareIcon className="w-3 h-3" />
              </div>
              <div>
                <LifecycleStageStatePill
                  completedPercentage={objectiveCompletionRate}
                />
              </div>
            </div>
            <div id="updateList" className="text-xs flex flex-col">
              {hasRisks && belowEngagementThreshold && (
                <>
                  {risks.map((risk) => (
                    <CohortRisk
                      objective={objective}
                      risk={risk}
                      close={close}
                    />
                  ))}
                </>
              )}
              <div className="flex flex-row items-center gap-1 py-2 border-l border-dashed border-body-text-lightest pl-4 ml-3">
                <CheckCircleIcon className="w-5 h-5 text-body-text-lighter mr-1.5" />
                <span className="font-semibold text-body-text">
                  {objectiveCompletionRate}%
                </span>
                have completed, or are on-track to complete
              </div>
              <div className="flex flex-row items-center gap-1 py-2 border-l border-dashed border-body-text-lightest pl-4 ml-3">
                <ClockIcon className="w-5 h-5 text-body-text-lighter mr-1.5" />{" "}
                Average time to completion is
                <span className="font-semibold text-body-text">
                  {avgTimeToCompletion} days
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
