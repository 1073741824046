import React, { Dispatch, useCallback, useState } from "react";
import Drawer from "../../patterns/Drawer";
import { MessagingSenderType, ReferralType } from "../../__generated__/graphql";
import FollowUpActionsBuilderSection from "./builder_sections/FollowUpActionsBuilderSection";
import FollowUpActionsDrawer from "./drawer/FollowUpActionsDrawer";
import { ReferralUpdate } from "./reducer";

interface Props {
  referral: ReferralType;
  dispatch: Dispatch<ReferralUpdate>;
  senders: MessagingSenderType[];
}

type DrawerComponent = typeof FollowUpActionsDrawer;

export default function ReferralForm({ referral, dispatch, senders }: Props) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  if (!referral) {
    return;
  }

  return (
    <>
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            referral={referral}
            close={closeDrawer}
            senders={senders}
          />
        </Drawer>
      )}

      <div className="flex grow flex-col mb-8 gap-y-6">
        <section className="flex flex-col gap-2">
          <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
            <FollowUpActionsBuilderSection
              referral={referral}
              actionList={referral.actionsOnReferred}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: FollowUpActionsDrawer,
                });
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
}
