import { Routes, Route, useMatch, useNavigate } from "react-router-dom";
import CampaignMain from "../campaigns";
import Campaign from "../campaign";
import Cohort from "../cohort";
import Behavior from "../behavior";
import Referral from "../referral";
import LifecycleStage from "../lifecycle_stage";
import NewObjective from "../lifecycle_stage/NewObjective";
import ObjectiveMain from "../objective";
import NewReferrerRefereePair from "../referrals/NewReferrerRefereePair";
import Dashboard from "../dashboard";
import SignalMonitor from "../signal_monitors";
import ReferralsCustomerDashboard from "../dashboard/ReferralsCustomerDashboard";
import { Nav } from "../Nav";
import SettingsMain from "../settings";
import PeopleMain from "../people";
import PersonMain from "../person";
import StoryBook from "../patterns/StoryBook";
import AttributesMain from "../settings/attributes";
import { Toaster } from "react-hot-toast";
import Login from "../login";
import { cx } from "../lib/cx";
import MessagingMain from "../messaging";
import IncentivesMain from "../incentives/Incentives";
import ShopifyProductsMain from "../incentives/ShopifyProducts";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import FullScreenError from "../patterns/Errors";
import { useLazyQuery } from "@apollo/client";
import { GET_SESSION } from "../graphql/queries";
import { fetchCSRFFormToken } from "../lib/csrf";
import { useEffect, useMemo } from "react";
import { default as NewMessagingIntegrationModal } from "../messaging/NewIntegrationModal";
import { default as NewCrmIntegrationModal } from "../crms/NewIntegrationModal";
import { default as NewEventsIntegrationModal } from "../events/NewIntegrationModal";
import { default as NewLoopsIntegrationModal } from "../loops/NewIntegrationModal";
import SuccessModal from "../messaging/SuccessModal";
import CRMsMain from "../crms";
import { SessionContext } from "../SessionContext";
import TopBar from "../patterns/TopBar";
import TeammatesMain from "../teammates";
import ClaimInvite from "../signup/ClaimInvite";
import EventsMain from "../events";
import OrgsMain from "../orgs";
import OrgMain from "../org";
import EventSummariesMain from "../event_summaries";
import EventDetail from "../event_detail";
import FeatureFlags from "../FeatureFlags";
import LoopsMain from "../loops";
import NoAccess from "../patterns/NoAccess";
import SeedCampaignOrBehavior from "../seed_campaign_or_behavior";

export default function MainLayout() {
  const navigate = useNavigate();
  const isLogin = useMatch("/login");
  const isSignup = useMatch("/signup/claim_invite");

  const isLoggedOutView = isLogin || isSignup;

  const [getSession, { loading, error, data }] = useLazyQuery(GET_SESSION);

  useEffect(() => {
    if (!isLoggedOutView && !loading && !error && !data) {
      getSession();
    }
    if (data?.session) {
      Sentry.setTag("companyId", data.session.companyId);
      Sentry.setTag("companyName", data.session.companyName);
    }
    if (data?.userProfile) {
      Sentry.setTag("userId", data.userProfile.id);
    }
  }, [getSession, isLoggedOutView, loading, error, data]);

  const logout = async () => {
    const token = await fetchCSRFFormToken();
    let formData = new FormData();
    formData.append("csrfmiddlewaretoken", token);
    formData.append("next", "/");

    fetch("/hijack/release/", { method: "POST", body: formData }).then(() =>
      window.location.replace("/"),
    );
  };

  const sessionData = useMemo(
    () =>
      data
        ? {
            session: data.session,
            userProfile: data.userProfile,
            attributes: {
              person: data.allPersonAttributes,
              org: data.allOrgAttributes,
            },
            eventNames: data.allEvents,
            featureFlags: data.featureFlags,
            peopleCount: data.allPeople.total,
            orgCount: data.allOrgs.total,
            dashboardConfigs: data.dashboardConfigs,
          }
        : {},
    [data],
  );

  if (!isLoggedOutView && !data) {
    return <></>;
  }

  return (
    <>
      {data?.session.isImpersonation && (
        <div className="fixed z-50 flex h-16 w-full bg-red-400 text-white lg:inset-y-0">
          <div className="ml-2 flex-grow">
            You are currently impersonating a customer{" "}
          </div>
          <button className="mr-2" onClick={logout}>
            Logout
          </button>
        </div>
      )}
      <SessionContext.Provider value={sessionData}>
        {!isLoggedOutView && <TopBar />}
        <div className={cx("flex", !isLoggedOutView && "mt-12")}>
          {!isLoggedOutView && <Nav />}
          <main
            className={cx(
              !isLoggedOutView &&
                "lg:ml-72 bg-white rounded-2xl flex w-full mr-4 mb-4 overflow-x-hidden",
              "min-h-[calc(100vh-56px)]",
            )}
          >
            <div className="flex flex-col w-full">
              <ErrorBoundary
                fallback={({ error, resetError }) => (
                  <FullScreenError resetError={resetError} />
                )}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      data?.session?.settings.isReferralsCustomer ? (
                        <ReferralsCustomerDashboard />
                      ) : (
                        <Dashboard />
                      )
                    }
                  />
                  <Route
                    path="/referrals"
                    element={<ReferralsCustomerDashboard />}
                  />
                  <Route
                    path="/referrals/signals"
                    element={<ReferralsCustomerDashboard scrollTo="signals" />}
                  />
                  <Route
                    path="/referrals/campaigns"
                    element={
                      <ReferralsCustomerDashboard scrollTo="campaigns" />
                    }
                  />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/dashboard/lifecycle_stages"
                    element={<Dashboard scrollTo="lifecycleStages" />}
                  />
                  <Route
                    path="/dashboard/behaviors"
                    element={<Dashboard scrollTo="behaviors" />}
                  />
                  <Route
                    path="/dashboard/campaigns"
                    element={<Dashboard scrollTo="campaigns" />}
                  />
                  <Route
                    path="/dashboard/referrals"
                    element={<Dashboard scrollTo="referrals" />}
                  />
                  <Route path="/campaigns" element={<CampaignMain />} />
                  <Route
                    path="/campaigns/:id"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <Campaign />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/behaviors/:id"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <Behavior />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/cohorts/:id"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <Cohort />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/referrals/:id"
                    element={
                      data?.session?.settings.canAccessReferrals ? (
                        <ErrorBoundary fallback={<FullScreenError />}>
                          <Referral />
                        </ErrorBoundary>
                      ) : (
                        <NoAccess />
                      )
                    }
                  />
                  <Route
                    path="/referrals/new"
                    element={
                      data?.session?.settings.canAccessReferrals ? (
                        <ErrorBoundary fallback={<FullScreenError />}>
                          <NewReferrerRefereePair />
                        </ErrorBoundary>
                      ) : (
                        <NoAccess />
                      )
                    }
                  />
                  <Route
                    path="/lifecycle_stages/:id"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <LifecycleStage />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/lifecycle_stages/:id/objectives/new"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <NewObjective />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/lifecycle_stages/:id/objectives/:objectiveId"
                    element={
                      <ErrorBoundary fallback={<FullScreenError />}>
                        <ObjectiveMain />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/monitor/:monitorName"
                    element={<SignalMonitor />}
                  />
                  <Route path="/events" element={<EventSummariesMain />} />
                  <Route path="/events/:eventName" element={<EventDetail />} />
                  <Route path="/audience/people" element={<PeopleMain />} />
                  <Route
                    path="/audience/organizations"
                    element={<OrgsMain />}
                  />
                  <Route
                    path="/audience/attributes"
                    element={<AttributesMain />}
                  />
                  <Route path="/audience/people/:id" element={<PersonMain />} />
                  <Route
                    path="/audience/organizations/:id"
                    element={<OrgMain />}
                  />
                  <Route path="/integrations" element={<SettingsMain />} />
                  <Route path="/settings" element={<SettingsMain />} />
                  <Route path="/incentives" element={<IncentivesMain />} />
                  <Route
                    path="/incentives/shopify"
                    element={<ShopifyProductsMain />}
                  />
                  <Route path="/settings/messaging" element={<MessagingMain />}>
                    <Route
                      path="new"
                      element={
                        <NewMessagingIntegrationModal
                          closeModal={() => {
                            navigate("/settings/messaging");
                          }}
                        />
                      }
                    />
                    <Route
                      path="new/success"
                      element={
                        <SuccessModal
                          closeModal={() => {
                            navigate("/settings/messaging");
                          }}
                        />
                      }
                    />
                  </Route>
                  <Route path="/settings/loops" element={<LoopsMain />}>
                    <Route
                      path="new"
                      element={
                        <NewLoopsIntegrationModal
                          closeModal={() => {
                            navigate("/settings/loops");
                          }}
                        />
                      }
                    />
                    <Route
                      path="new/success"
                      element={
                        <SuccessModal
                          closeModal={() => {
                            navigate("/settings/loops");
                          }}
                        />
                      }
                    />
                  </Route>
                  <Route path="/settings/crms" element={<CRMsMain />}>
                    <Route
                      path="new"
                      element={
                        <NewCrmIntegrationModal
                          closeModal={() => {
                            navigate("/settings/crms");
                          }}
                        />
                      }
                    />
                    <Route
                      path="new/success"
                      element={
                        <SuccessModal
                          closeModal={() => {
                            navigate("/settings/crms");
                          }}
                        />
                      }
                    />
                  </Route>
                  <Route path="/settings/events" element={<EventsMain />}>
                    <Route
                      path="new"
                      element={
                        <NewEventsIntegrationModal
                          closeModal={() => {
                            navigate("/settings/events");
                          }}
                        />
                      }
                    />
                  </Route>
                  <Route path="/teammates" element={<TeammatesMain />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/signup/claim_invite"
                    element={<ClaimInvite />}
                  />
                  <Route path="/patterns/storybook" element={<StoryBook />} />
                  <Route path="/feature-flags" element={<FeatureFlags />} />
                  <Route path="/seeder" element={<SeedCampaignOrBehavior />} />
                </Routes>
                <Toaster
                  position="bottom-center"
                  containerStyle={{
                    zIndex: 100000,
                  }}
                />
              </ErrorBoundary>
            </div>
            <div id="DrawerToBe"></div>
          </main>
        </div>
      </SessionContext.Provider>
    </>
  );
}
