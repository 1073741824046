import { useQuery } from "@apollo/client";
import {
  LifecycleStageType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import { GET_LIFECYCLE_STAGE_COHORT_RISK } from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import EngagementCircle from "../patterns/EngagementCircle";
import SectionHeader from "../patterns/SectionHeader";
import Alert from "../patterns/Alert";
import ObjectiveCard from "./ObjectiveCard";

export default function LifecycleStageBreakdown({
  lifecycleStage,
  close,
}: {
  lifecycleStage: LifecycleStageType;
  close: () => void;
}) {
  const target =
    lifecycleStage.behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";
  const entered = lifecycleStage.behavior.stats.entered.count;
  const completed = lifecycleStage.behavior.stats.completed.count;
  const completedPercentage =
    entered && Math.round((completed / entered) * 100);
  const avgTimeToCompletion =
    lifecycleStage.behavior.stats.timeToCompletion.average.toFixed(2);

  const { data, loading } = useQuery(GET_LIFECYCLE_STAGE_COHORT_RISK, {
    variables: { id: lifecycleStage.id },
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <SkeletonScreen label="Talking to Agents..." />;

  const filteredRisks = data?.lifecycleStageCohortRisk.filter(
    (risk) => risk.numberOfOrgs > 0,
  );

  return (
    <div className="flex flex-col bg-pavlov-bg rounded-xl mx-4 p-4 gap-2">
      <div className="flex flex-row items-center justify-between">
        <SectionHeader
          title={`${lifecycleStage.name}`}
          description={`${completed.toLocaleString()} out of ${entered.toLocaleString()} ${target} completed, averaging ${avgTimeToCompletion} days to complete`}
          size="large"
        />
        <div className="w-16 h-auto">
          <EngagementCircle
            completionPercentage={completedPercentage}
            showText={true}
          />
        </div>
      </div>

      {lifecycleStage.objectiveBehaviors.length <= 0 ? (
        <Alert
          type="warning"
          message="This Lifecycle has no Objectives defined"
        />
      ) : (
        <div className="flex flex-col gap-4">
          {lifecycleStage.objectiveBehaviors.map((objective, index) => (
            <ObjectiveCard
              key={objective.id} // Ensure unique keys for each component
              objective={objective}
              risks={filteredRisks?.filter(
                (risk) => risk.objectiveId === objective.id,
              )}
              close={close}
              index={index} // Pass the index as a prop
              className="fade-in-up" // Add the fade-in-up animation class
            />
          ))}
        </div>
      )}
    </div>
  );
}
