import { useCallback } from "react";
import Alert from "../Alert";

interface Props {
  autoComplete?: string;
  autoFocus?: boolean;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value: string | number;
  required?: boolean;
  onChange: (s: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  min?: string;
  step?: string;
  disabled?: boolean;
  size?: string;
  error?: string;
}

export default function Input({
  autoComplete,
  autoFocus = false,
  type = "text",
  placeholder,
  value,
  required = false,
  onChange,
  onKeyDown = () => undefined,
  min,
  step,
  disabled = false,
  size = "default",
  error,
}: Props) {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange],
  );
  const sizeClasses = size === "large" ? "p-3" : "px-2 pl-3";
  return (
    <>
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        type={type}
        className={`mt-2 block w-full rounded-lg border ${error ? "border-red" : "border-rule-color"} bg-white text-xs font-normal leading-6 tracking-wide text-body-text focus:border-blue-800 focus:ring-blue-300 ${sizeClasses}`}
        aria-describedby="helper-text-explanation"
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        required={required}
        disabled={disabled}
        onKeyDown={onKeyDown}
        min={min}
        step={step}
      />
      {error && (
        <div className="mt-2">
          <Alert type="error" size="small" message={error} />
        </div>
      )}
    </>
  );
}
