import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_BEHAVIOR_STATS_FOR_MEMBER } from "../../graphql/queries";
import * as Plot from "@observablehq/plot";
import Card from "../Card";
import SectionHeader from "../SectionHeader";
import { cx } from "../../lib/cx";
import { truncate } from "../../lib/string";
import { useChartDimensions } from "../../hooks/useChartDimensions";

const PillButton = ({ label, active, onClick }) => {
  return (
    <button
      className={cx(
        "px-4 py-1.5 rounded-full text-[10px] font-medium text-white animate",
        active
          ? "bg-body-text hover:bg-grey-600"
          : "bg-grey-400 hover:bg-grey-600",
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

interface Props {
  member;
}

export default function BehaviorTimelines({ member }: Props) {
  const initialChartSettings = {
    marginTop: 16,
    marginLeft: 160,
    marginBottom: 48,
  };

  const [ref, dims] = useChartDimensions(initialChartSettings);
  const { data: queryResult } = useQuery(GET_BEHAVIOR_STATS_FOR_MEMBER, {
    variables: { memberId: member.id },
  });

  const [timeCutoff, setTimeCutoff] = useState(null);

  const handleTimeCutoffSelect = useCallback(
    (days: number): void => {
      if (timeCutoff === days) {
        setTimeCutoff(null);
      } else {
        setTimeCutoff(days);
      }
    },
    [timeCutoff],
  );

  const names = useMemo(() => {
    if (!queryResult) return [];

    return queryResult?.behaviorStatsForMember.map((b) => {
      if (b.behaviorName === "") {
        return b.behaviorId.slice(-6);
      }
      return b.behaviorName;
    });
  }, [queryResult]);

  const events = useMemo(() => {
    if (!queryResult) return [];

    const transformed = [];

    queryResult?.behaviorStatsForMember.forEach((b, i) => {
      b.completed.forEach((c) =>
        transformed.push({
          id: b.behaviorId,
          name: b.behaviorName || b.behaviorId.slice(-6),
          idx: i + 1,
          happenedAt: c,
        }),
      );
    });

    return transformed;
  }, [queryResult]);

  const completions = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult?.behaviorStatsForMember.map((b) => ({
      id: b.behaviorId,
      name: b.behaviorName || b.behaviorId.slice(-6),
      completed: Math.max(...b.completed),
    }));
    return transformed;
  }, [queryResult]);

  const timeLines = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult?.behaviorStatsForMember
      .map((b, i) => [
        {
          id: b.behaviorId,
          name: b.behaviorName || b.behaviorId.slice(-6),
          t: timeCutoff
            ? Math.max(Math.max(...b.completed) - timeCutoff, 0)
            : 0,
        },
        {
          id: b.behaviorId,
          name: b.behaviorName || b.behaviorId.slice(-6),
          t: Math.max(...b.completed),
        },
      ])
      .flat();
    return transformed;
  }, [queryResult, timeCutoff]);

  const averages = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult?.behaviorStatsForMember.map((b) => ({
      id: b.behaviorId,
      name: b.behaviorName || b.behaviorId.slice(-6),
      average: b.average,
    }));
    return transformed;
  }, [queryResult]);

  const maxDaysSince = useMemo(
    () =>
      Math.max(
        ...events.map((d) => d.happenedAt),
        ...averages.map((d) => d.average),
        1,
      ),
    [events, averages],
  );

  const minDaysSince = useMemo(
    () => Math.max(maxDaysSince - (timeCutoff || maxDaysSince), 0),
    [maxDaysSince, timeCutoff],
  );

  useEffect(() => {
    if (events === undefined) return;
    if (!ref.current) return;

    const rowHeight = 200; // Set a default height for each row

    const plot = Plot.plot({
      width: dims.width,
      height: rowHeight,
      marginTop: dims.marginTop,
      marginRight: dims.marginRight,
      marginBottom: dims.marginBottom,
      marginLeft: dims.marginLeft,
      padding: 0.4,
      x: {
        domain: [minDaysSince, maxDaysSince + 1],
        label: "Days since entered",
        labelAnchor: "center",
        labelArrow: "none",
        labelOffset: 45,
      },
      style: {
        fontSize: "9pt",
        color: "gray",
      },
      symbol: { legend: true },
      marks: [
        Plot.frame({ anchor: "bottom" }),
        Plot.frame({ anchor: "left" }),
        Plot.gridY(
          averages.map((d) => d.name),
          {
            stroke: "red",
            strokeWidth: 1,
            strokeDasharray: "2,4",
          },
        ),
        Plot.line(timeLines, {
          x: "t",
          y: "name",
          z: "id",
          stroke: "#D5D9D4",
          strokeWidth: 1,
          strokeDasharray: "2,4",

          filter: (d) => d.t >= minDaysSince,
        }),
        Plot.dot(events, {
          x: "happenedAt",
          y: "name",
          stroke: "grey",
          strokeWidth: 1,
          fill: "white",
          r: 6,
          filter: (d) => d.happenedAt >= minDaysSince,
        }),
        Plot.dot(completions, {
          x: "completed",
          y: "name",
          stroke: "black",
          strokeWidth: 1,
          fill: "black",
          r: 6,
          filter: (d) => d.completed >= minDaysSince,
        }),
        Plot.tip(
          events,
          Plot.pointer({
            x: "happenedAt",
            y: "name",
            title: (d) => `${Math.round(d.happenedAt)} days since entered`,
            filter: (d) => d.happenedAt >= minDaysSince,
          }),
        ),
        Plot.dot(averages, {
          x: "average",
          y: "name",
          r: 6,
          strokeWidth: 1,
          stroke: "#A79A6D", // Stroke color
          fill: "#A79A6D", // Fill color
          fillOpacity: 0.33, // Fill opacity
          filter: (d) => d.average >= minDaysSince,
        }),
        Plot.axisY({
          label: null,
          ticks: names,
          tickFormat: (d) => truncate(d, 17),
          // left align the y axis labels
          textAnchor: "start",
          dx: -122,
          tickSize: 0,
        }),
      ],
    });

    ref.current.append(plot);
    return () => plot.remove();
  }, [
    events,
    timeLines,
    averages,
    completions,
    minDaysSince,
    maxDaysSince,
    dims,
    names,
    ref,
  ]);

  return (
    <div className="">
      <SectionHeader title="Performance" icon="Performance"></SectionHeader>
      <Card>
        <div className="flex flex-row gap-1 mb-4 justify-end">
          <PillButton
            label="Last 7 days"
            active={timeCutoff === 7}
            onClick={() => handleTimeCutoffSelect(7)}
          />
          <PillButton
            label="Last 30 days"
            active={timeCutoff === 30}
            onClick={() => handleTimeCutoffSelect(30)}
          />
          <PillButton
            label="Last 90 days"
            active={timeCutoff === 90}
            onClick={() => handleTimeCutoffSelect(90)}
          />
        </div>

        <div ref={ref}></div>
        <div className="flex flex-row items-center gap-4 justify-end text-gray-500">
          <div className="flex flex-row items-center gap-1">
            <span>Event</span>
            <svg height={16} width={16}>
              <circle
                cx={8} // x-coordinate of the center
                cy={8} // y-coordinate of the center
                r={6} // radius of the circle
                stroke="grey" // stroke color
                strokeWidth={1} // stroke width
                fill="white" // fill color
                fillOpacity={1} // fill opacity
              ></circle>
            </svg>
          </div>
          <div className="flex flex-row items-center gap-1">
            <span>Completed</span>
            <svg height={16} width={16}>
              <circle
                cx={8} // x-coordinate of the center
                cy={8} // y-coordinate of the center
                r={6} // radius of the circle
                stroke="black" // stroke color
                strokeWidth={1} // stroke width
                fill="black" // fill color
                fillOpacity={1} // fill opacity
              ></circle>
            </svg>
          </div>
          <div className="flex flex-row items-center gap-1">
            <span>Average</span>
            <svg height={16} width={16}>
              <circle
                cx={8} // x-coordinate of the center
                cy={8} // y-coordinate of the center
                r={6} // radius of the circle
                stroke="#A79A6D" // stroke color
                strokeWidth={1} // stroke width
                fill="#A79A6D" // fill color
                fillOpacity={0.33} // fill opacity
              ></circle>
            </svg>
          </div>
        </div>
      </Card>
    </div>
  );
}
