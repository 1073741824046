import { useState, useCallback, Dispatch } from "react";
import { CohortUpdate } from "./reducer";
import { CohortType } from "../../__generated__/graphql";
import Drawer from "../../patterns/Drawer";
import AudienceBuilderSection from "./builder_sections/AudienceBuilderSection";
import AudienceDrawer from "./drawer/AudienceDrawer";
import CompletionCriteriaBuilderSection from "./builder_sections/CompletionCriteriaBuilderSection";
import CompletionCriteriaDrawer from "./drawer/CompletionCriteriaDrawer";
import SummarySettingsSection from "./builder_sections/SummarySettingsSection";
import SummarySettingsDrawer from "./drawer/SummarySettingsDrawer";

interface Props {
  dispatch: Dispatch<CohortUpdate>;
  cohort: CohortType;
}

type DrawerComponent =
  | typeof AudienceDrawer
  | typeof CompletionCriteriaDrawer
  | typeof SummarySettingsDrawer;

export default function CohortForm({ dispatch, cohort }: Props) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  return (
    <>
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            cohort={cohort}
            close={closeDrawer}
          />
        </Drawer>
      )}
      <div className="flex grow flex-col mx-8 mb-32 gap-y-6">
        <section className="flex flex-col gap-2">
          <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
            <AudienceBuilderSection
              cohort={cohort}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: AudienceDrawer,
                });
              }}
            />
            <CompletionCriteriaBuilderSection
              cohort={cohort}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: CompletionCriteriaDrawer,
                });
              }}
            />
            <SummarySettingsSection
              cohort={cohort}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: SummarySettingsDrawer,
                });
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
}
